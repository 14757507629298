<div class="password-checker">
  <div class="password-checker-title">
    {{'password_checkbox.main_checkbox' | translate}}
  </div>
  <div class="instruction-step {{passwordStrength.count.checked ? 'password-checked' : ''}}">
        <span class="check-icon">
          <svg width="15" height="15">
          <use href="assets/images/sprite.svg#success" *ngIf="passwordStrength.count.checked"/>
          <use href="assets/images/sprite.svg#fail" *ngIf="!passwordStrength.count.checked"/>
        </svg>
        </span>
    <span class="check-text">{{'password_checkbox.length' | translate}}</span>
  </div>
  <div class="instruction-step {{passwordStrength.capitalLetter.checked ? 'password-checked' : ''}}">
        <span class="check-icon">
          <svg width="15" height="15">
          <use href="assets/images/sprite.svg#success" *ngIf="passwordStrength.capitalLetter.checked"/>
          <use href="assets/images/sprite.svg#fail" *ngIf="!passwordStrength.capitalLetter.checked"/>
        </svg>
        </span>
    <span class="check-text">{{'password_checkbox.capital_letter' | translate}}</span>
  </div>
  <div class="instruction-step {{passwordStrength.smallLetter.checked ? 'password-checked' : ''}}">
        <span class="check-icon">
          <svg width="15" height="15">
          <use href="assets/images/sprite.svg#success" *ngIf="passwordStrength.smallLetter.checked"/>
          <use href="assets/images/sprite.svg#fail" *ngIf="!passwordStrength.smallLetter.checked"/>
        </svg>
        </span>
    <span class="check-text">{{'password_checkbox.small_letter' | translate}}</span>
  </div>
  <div class="instruction-step {{passwordStrength.specialCharacter.checked ? 'password-checked' : ''}}">
        <span class="check-icon">
          <svg width="15" height="15">
          <use href="assets/images/sprite.svg#success" *ngIf="passwordStrength.specialCharacter.checked"/>
          <use href="assets/images/sprite.svg#fail" *ngIf="!passwordStrength.specialCharacter.checked"/>
        </svg>
        </span>
    <span class="check-text">{{'password_checkbox.special_character' | translate}}</span>
  </div>
  <div class="instruction-step {{passwordStrength.number.checked ? 'password-checked' : ''}}">
        <span class="check-icon">
          <svg width="15" height="15">
          <use href="assets/images/sprite.svg#success" *ngIf="passwordStrength.number.checked"/>
          <use href="assets/images/sprite.svg#fail" *ngIf="!passwordStrength.number.checked"/>
        </svg>
        </span>
    <span class="check-text">{{'password_checkbox.one_number' | translate}}</span>
  </div>
</div>
