<div class="footer-upper">
  <div class="row">
    <div class="d-inline-flex justify-content-center col-md-3 col-sm-12 mb-md-0 mb-4 mb-md-0 mb-2">
      <alm-root-footer-almentor></alm-root-footer-almentor>
    </div>
    <div class="d-inline-flex justify-content-center col-md-3 col-sm-12 mb-md-0 mb-4">
      <alm-root-footer-explore [userSubscribed]="userSubscribed"></alm-root-footer-explore>
    </div>
    <div class="d-inline-flex justify-content-center col-md-3 col-sm-12 mb-md-0 mb-4">
      <alm-root-footer-business></alm-root-footer-business>
    </div>
    <div class="d-inline-flex justify-content-center col-md-3 col-sm-12 mb-md-0 mb-4">
      <alm-root-footer-app></alm-root-footer-app>
    </div>
  </div>
</div>
