export enum EUserType {
  B2C,
  B2B
}

export enum ETheme {
  Dark,
  Light
}

export enum ELanguage {
  AR = 'ar',
  EN = 'en'
}

export interface IMessageUser {
  uuid: string,
  type: EUserType,
  link: string | null
}

export interface IConnectorUserInfo{
  lang: ELanguage,
  theme: ETheme,
  user: IMessageUser | null
}
