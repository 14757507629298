import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplifiedFooterComponent } from './simplified-footer.component';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
  declarations: [SimplifiedFooterComponent],
  exports: [
    SimplifiedFooterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class SimplifiedFooterModule { }
