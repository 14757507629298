<div class="login-form">
  <form action="" [formGroup]="loginFormCredentials">
    <mat-form-field class="form-group with-prefix" appearance="outline">
      <input matInput type="email" name="email" maxlength="64"
             placeholder="{{'login_container.placeholders' | translate | translateCut: 0}}" class="form-control" formControlName="email">
      <div matPrefix class="prefix-icon">
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#email" />
        </svg>
      </div>
      <mat-error *ngIf="getFormFieldError(loginFormCredentials.controls.email) as message">
        {{'login_container.local_errors' | translate | translateCut: message - 1}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-group with-prefix with-suffix" appearance="outline">
      <input #password matInput type="password" class="form-control password"
             placeholder="{{'login_container.placeholders' | translate | translateCut: 1}}" formControlName="password">
      <div matPrefix class="prefix-icon">
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#password" />
        </svg>
      </div>
      <div matSuffix class="suffix-icon">
        <svg width="22" height="18" (click)="password.type=password.type=='password'?'text':'password'">
          <use href="assets/images/sprite.svg#visibility" *ngIf="password.type=='password'" />
          <use href="assets/images/sprite.svg#visibility-off" *ngIf="password.type=='text'" />
        </svg>
      </div>
      <mat-error *ngIf="getFormFieldError(loginFormCredentials.controls.password) as message">
        {{'login_container.local_errors' | translate | translateCut: message - 1}}
      </mat-error>
    </mat-form-field>

    <mat-checkbox class="font-italic login-checkbox" formControlName="rememberMe">
      {{'login_container.remember_me' | translate}}
    </mat-checkbox>

    <div class="text-center btn-container">
      <button (click)="login()" [disabled]="loginFormCredentials.invalid || loginIsClicked" class="btn btn-danger">
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#email"/>
        </svg>
        {{'login_container.login_method' | translate | translateCut: 2}}
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <button type="button" (click)="forgetPasswordClicked()" class="btn btn-link link-underline">
        {{'login_container.forget_password' | translate}}
      </button>
    </div>
  </form>
</div>
