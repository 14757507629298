import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterestsContainerComponent } from './interests-container.component';
import { InterestCheckboxComponent } from './interest-checkbox/interest-checkbox.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
    declarations: [InterestsContainerComponent, InterestCheckboxComponent],
    exports: [
        InterestsContainerComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class InterestsContainerModule { }
