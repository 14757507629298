import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {UserService} from '@services/integrations/user/user.service';
import {IUserInfo, IUserType} from "@interfaces/authorized-user/user.interface";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'alm-root-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() isCourseViewer?: boolean;
  @Input() userInfoSub?: BehaviorSubject<IUserInfo | null>;
  @Input() isB2bUser: boolean = false;
  userSubscribed?: boolean;
  userType?: IUserType;
  private subscriptions: Subscription[] = [];
  constructor(private languageControl: LanguageControlService, private userService: UserService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.userInfoSub) {
      let userSub = this.userInfoSub.subscribe((userInfo) => {
        if(userInfo) {
          this.userSubscribed = userInfo.subscribed;
          this.userType = userInfo.userType;
        }
      });
      this.subscriptions.push(userSub);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    })
  }

}
