import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {EGender, ICity, ICountry, IDemographics} from '@interfaces/common/auth.interface';
import {MatSelectChange} from "@angular/material/select";
import {ReplaySubject} from "rxjs";
import {stringListGenerator, filterArrayByName} from "@utilities/array.utility";
import {LookupsService} from "@services/integrations/lookups/lookups.service";
import {BrazeGenders, BrazeUserAttributesService} from '@services/marketing/braze/events/braze-user-attributes.service';

@Component({
  selector: 'alm-root-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit {

  @Output() demographicsObject: EventEmitter<IDemographics> = new EventEmitter<IDemographics>();
  demographicsForm: UntypedFormGroup;
  countries: ICountry[] = [];
  countryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);
  cities: ICity[] = [];
  cityFilterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredCities: ReplaySubject<ICity[]> = new ReplaySubject<ICity[]>(1);
  days: string[] = [];
  months: string[] = [];
  years: string[] = [];

  constructor(private fb: UntypedFormBuilder, private languageControl: LanguageControlService, private lookupsService: LookupsService,
              private brazeUserAttributesService: BrazeUserAttributesService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.demographicsForm = this.fb.group({
      // country: new FormControl('', Validators.required),
      // city: new FormControl({value: '', disabled: true}, Validators.required),
      phoneNumber: new UntypedFormControl('',
        Validators.compose([Validators.maxLength(20), Validators.minLength(8)])),
      gender: new UntypedFormControl(''),
      day: new UntypedFormControl(''),
      month: new UntypedFormControl(''),
      year: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.lookupsService.getAllCountriesByLanguageId('demographics').then(countries => {
      this.countries = countries;
      this.filteredCountries.next(this.countries);
    });
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let minYear = currentYear - 100;
    let maxYear = currentYear - 12;
    this.years = stringListGenerator(minYear, maxYear, false).sort().reverse();
    this.months = stringListGenerator(1, 12, true);
    this.days = stringListGenerator(1, 31, true);
  }

  filterArrayByName(searchString: string, source: any[], filtered: ReplaySubject<any[]>) {
    filtered.next(filterArrayByName(searchString, source));
  }

  onCountrySelectionChange($event: MatSelectChange) {
    let country: ICountry = $event.value;
    this.demographicsForm.controls['phoneNumber'].enable();
    // this.demographicsForm.controls['city'].reset('');
    // this.lookupsService.getAllCitiesById('demographics', country.id).then(cities => {
    //   this.cities = cities;
    //   this.filteredCities.next(this.cities);
    //   this.demographicsForm.controls['city'].enable();
    // });
  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required)
        return 1;
      if (fieldControl?.errors?.minlength || fieldControl?.errors?.maxLength)
        return 2;
    }
    return 0;
  }

  checkDateInsertion(): void {
    if (this.demographicsForm.controls.day) {
      this.demographicsForm.controls.month.setValidators([Validators.required]);
      this.demographicsForm.controls.month.updateValueAndValidity();
      this.demographicsForm.controls.year.setValidators([Validators.required]);
      this.demographicsForm.controls.year.updateValueAndValidity();
    }
  }

  saveDemographicsInfo() {
    let demographicsInfo: IDemographics = {
      gender: this.demographicsForm.value.gender,
      birthDate: this.demographicsForm.value.day ? this.demographicsForm.value.year + '-' + this.demographicsForm.value.month +
        '-' + this.demographicsForm.value.day : undefined,
      // countryId: this.demographicsForm.value.country.id,
      // cityId: this.demographicsForm.value.city.id,
      mobileNumber: this.demographicsForm.value.phoneNumber
    };
    this.demographicsObject.emit(demographicsInfo);
    this.brazeUserAttributesService.setUserDemographics({
      country: this.countries.find(c => c.id === demographicsInfo.countryId)?.code || '',
      city: this.cities.find(c => c.id === demographicsInfo.cityId)?.name || '',
      gender: demographicsInfo.gender === EGender.Male ? BrazeGenders.MALE :  BrazeGenders.FEMALE,
      mobileNumber: demographicsInfo.mobileNumber,
      birthDate: demographicsInfo.birthDate ? new Date(demographicsInfo.birthDate) : undefined,
    });
  }

  onMobileChange(phoneNumber: string | null | undefined) {
    const phoneNumberControl = this.demographicsForm.get('phoneNumber');
    if (phoneNumber === null) {
      phoneNumberControl?.reset('0');
      phoneNumberControl?.markAsPristine();
    } else if (phoneNumber === undefined) {
      phoneNumberControl?.reset(phoneNumber);
      phoneNumberControl?.markAsPristine();
    } else {
      phoneNumberControl?.reset(phoneNumber);
      phoneNumberControl?.markAsDirty();
    }
    phoneNumberControl?.updateValueAndValidity();
  }

  generateDays(): void {
    const year = this.demographicsForm.value.year ? this.demographicsForm.value.year : 2022;
    const daysCount = new Date(year, this.demographicsForm.value.month, 0).getDate();
    this.days = stringListGenerator(1, daysCount, true);
    if (!this.days.includes(this.demographicsForm.value.day)) {
      this.demographicsForm.controls.day.setValue('01');
      this.checkDateInsertion();
    }
  }
}
