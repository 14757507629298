import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import {HttpErrorResponse} from "@angular/common/http";
import {ScopeContext} from "@sentry/types/types/scope";
@Injectable({
  providedIn: 'root'
})
export class SentryService {
  setUser(user: Sentry.User | null) {
    Sentry.setUser(user);
  }

  captureMessage(message: string, captureContext?: Partial<ScopeContext>) {
    const defaultContext: Partial<ScopeContext> = {
      level: 'debug',
    }
    Sentry.captureMessage(message, {...defaultContext, ...captureContext});
  }

  captureException(exception: any) {
    Sentry.captureException(exception);
  }

  reportHttpErrorResponse(message: string, errorResponse: HttpErrorResponse) {
    Sentry.captureMessage(message, {
      level: 'error',
      tags: {
        'error.type': 'Http',
        'request.url': errorResponse.url,
        'request.status': errorResponse.status,
        'request.statusText': errorResponse.statusText,
      },
    });
  }
}
