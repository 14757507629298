import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'alm-root-main-footer-v2',
  templateUrl: './main-footer-v2.component.html',
  styleUrls: ['./main-footer-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainFooterV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
