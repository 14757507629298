import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, EMPTY, fromEvent, interval, Observable} from "rxjs";
import {Router} from "@angular/router";
import {ELanguage, ETheme, EUserType, IConnectorUserInfo, IMessageUser} from '@interfaces/common/connector.interface';
import {FirebaseFirestoreService} from "@services/firebase/firebase-firestore.service";
import {filter, map} from "rxjs/operators";
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {environment} from '@environments/environment';
import {CookieService} from 'ngx-cookie';
import {EncryptionService} from '@services/request-token/encryption.service';
import {generateEncodedUserInfoCookie, getDecodedUserInfoFromCookie} from "@utilities/cookies.utility";
import {USER_PREFERENCES_COOKIE_KEY} from "@constants/cookies.constants";

declare const cookieStore: any;
interface IMessage {
  type: 'lang' | 'theme' | 'user' | 'loaded',
  process: 'SET' | 'GET',
  value?: ELanguage | ETheme | IMessageUser
}

@Injectable({
  providedIn: 'root'
})
export class ConnectorV2Service {
  connectorLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  connectorLanguage: BehaviorSubject<ELanguage> = new BehaviorSubject<ELanguage>(ELanguage.AR);
  connectorTheme: BehaviorSubject<ETheme> = new BehaviorSubject<ETheme>(ETheme.Dark);
  connectorUser: BehaviorSubject<IMessageUser | undefined> = new BehaviorSubject<IMessageUser | undefined>(undefined);
  isCheckingAuthorization: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private router: Router,
              private firestoreService: FirebaseFirestoreService,
              private cookieService: CookieService,
              private encryptionService: EncryptionService,
  ) {
  }

    initConnector(): void {
        this.applyUserLocalPreferences();
        this.subscribeToUserCookieChangesIfInBrowser();
        this.router.initialNavigation();
    }

    private applyUserLocalPreferences(): void {
        const localPreferences = this.getLocalPreferences();
        this.updateUserPreferences(localPreferences);
    }

    private subscribeToUserCookieChangesIfInBrowser(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.subscribeToUserCookieChanges();
        }
    }

    private subscribeToUserCookieChanges(): void {
        this.listenUserCookieChanges().subscribe((connectorUserInfo: IConnectorUserInfo) => {
            this.updateUserPreferences(connectorUserInfo);
        });
    }


  private onRemoteLanguageChange(language: ELanguage) {
    if (language === ELanguage.AR || language === ELanguage.EN) {
      if (language !== this.connectorLanguage.getValue()) {
        this.connectorLanguage.next(language);
      }
    }
  }

  private onRemoteThemeChange(theme: ETheme) {
    if (theme === ETheme.Dark || theme === ETheme.Light) {
      if (theme !== this.connectorTheme.getValue()) {
        this.connectorTheme.next(theme);
      }
    }
  }

  private onRemoteUserChange(user?: IMessageUser) {
    if (user?.uuid && (user?.type === EUserType.B2C || (user?.type === EUserType.B2B && user?.link))) {
      if (user.uuid !== this.connectorUser.getValue()?.uuid) {
        this.connectorUser.next(user);
      }
    } else {
      this.connectorUser.next(undefined);
    }
  }

  changeSelectedLanguage(lang: ELanguage) {
    let currentLocalPreferences = this.getLocalPreferences();
    this.setLocalPreferences({...currentLocalPreferences, lang});
  }

  changeSelectedTheme(theme: ETheme) {
    const currentLocalPreferences = this.getLocalPreferences();
    this.setLocalPreferences({...currentLocalPreferences, theme});
  }

  addUser(user: IMessageUser): Promise<boolean> {
    const currentLocalPreferences = this.getLocalPreferences();
    return new Promise<boolean>(resolve => {
      if (user.uuid && (user.type === EUserType.B2C || (user.type === EUserType.B2B && user.link))) {
        this.setLocalPreferences({...currentLocalPreferences, user});
        this.connectorUser.next(user);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  removeUser() {
    const currentLocalPreferences = this.getLocalPreferences();
    currentLocalPreferences.user = null;
    this.setLocalPreferences(currentLocalPreferences);
    this.connectorUser.next(undefined);
  }

  private setLocalPreferences(userInfo?: IConnectorUserInfo) {
    if (!userInfo) return;
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    if (isPlatformBrowser(this.platformId)) {
      const userInfoBase64 =  generateEncodedUserInfoCookie(userInfo);
      this.cookieService.put(USER_PREFERENCES_COOKIE_KEY, userInfoBase64, {
        domain: environment.platforms.base.link,
        path: '/',
        expires: cookieDate,
        sameSite: false,
        secure: true,
      });
    }
  }

  getLocalPreferences(): IConnectorUserInfo {
    const cookie = this.cookieService.get(USER_PREFERENCES_COOKIE_KEY);
    return getDecodedUserInfoFromCookie(cookie);
  }


  private getUpdatedCookie(): Observable<string> {
    if ('cookieStore' in window) {
      return fromEvent(cookieStore, 'change').pipe(
        map(() => this.cookieService.get(USER_PREFERENCES_COOKIE_KEY))
      );
    } else { //TODO Remove this fallback when the cookieStoreAPI is universally Available across all browsers
      return interval(5000).pipe(
        map(() => this.cookieService.get(USER_PREFERENCES_COOKIE_KEY))
      );
    }
  }

  private listenUserCookieChanges(): Observable<IConnectorUserInfo> {
    let lastCookieValue = this.cookieService.get(USER_PREFERENCES_COOKIE_KEY);

    return this.getUpdatedCookie().pipe(
      filter(currentCookieValue => lastCookieValue !== currentCookieValue),
      map(currentCookieValue => {
        lastCookieValue = currentCookieValue;
        return this.getLocalPreferences();
      })
    );
  }

  private updateUserPreferences(userLocalPreferences: IConnectorUserInfo) {
    this.onRemoteLanguageChange(userLocalPreferences.lang);
    this.onRemoteThemeChange(userLocalPreferences.theme);
    this.onRemoteUserChange(userLocalPreferences.user ?? undefined);
  }
}
