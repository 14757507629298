<div class="main-dialog-container" mat-dialog-content>
  <div class="dialog-device-manage-header">
    <button type="button" class="device-manage-close-btn" (click)="closeDialog()">
      <svg aria-hidden="true">
        <use href="assets/images/sprite.svg#times"/>
      </svg>
    </button>
  </div>
  <div class="dialog-device-manage-content">
    <alm-root-device-management></alm-root-device-management>
  </div>
</div>
