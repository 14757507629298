import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  ClientName,
  EventUserProperties,
  IGoogleTagManagerData,
  TrackingEventData,
  TrackingEvents,
  TrackingOptions
} from '@services/tracking/tracking.interface';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {TrackingDataService} from '@services/tracking/tracking-data.service';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {USER_TYPE} from '@constants/http.constants';
import {UserTypes} from '@interfaces/authorized-user/user.interface';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private gtmService: GoogleTagManagerService,
    private trackingData: TrackingDataService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(USER_TYPE) private userType: UserTypes,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.trackPageView();
    }
  }

  sendEvent<T extends keyof TrackingEvents>(
    eventName: T,
    trackingEventData: TrackingEventData<T>
  ) {
    if (isPlatformServer(this.platformId)) return;

    const eventData: TrackingOptions<T> = {
      event_properties: {
        ...this.trackingData.SuperProperties.getValue(),
        ...trackingEventData.event_properties,
      },
      metadata: this.trackingData.eventMetaData,
      clientName: ClientName.DataClient
    }
    if (this.userType === UserTypes.Anonymous) {
      this.trackingData.storeAnonymousUserProperties<T>(trackingEventData.user_properties);
    } else {
      eventData.user_properties = trackingEventData.user_properties;
    }
    const event: IGoogleTagManagerData<TrackingOptions<T>> = {
      eventName,
      eventData
    }
    this.gtmService.sendServerSideEvent<TrackingOptions<T>>(event);
  }

  private trackPageView(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const {pathname, protocol, search} = window.location
        this.sendEvent('page_view', {
          event_properties: {
            current_url_path: pathname,
            current_url_protocol: protocol,
            current_url_search: search,
          },
        });
      }
    });
  }
}
