import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from "@services/language/language-control.service";
import {IEmailLogin} from "@interfaces/common/auth.interface";

@Component({
  selector: 'alm-root-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {

  @Input() facebookId: string = '';
  @Input() googleId: string = '';
  @Input() redirectUrl: string = '';
  @Input() loginIsClicked: boolean = false;
  @Output() onSignupBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onForgetPasswordBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEmailLogin: EventEmitter<IEmailLogin> = new EventEmitter<IEmailLogin>();
  @Output() onFacebookLogin: EventEmitter<string> = new EventEmitter<string>();
  @Output() onGoogleLogin: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private languageControl: LanguageControlService,
  ) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

  signupButtonClicked() {
    this.onSignupBtnClicked.emit();
  }

  forgetPasswordBtnClicked() {
    this.onForgetPasswordBtnClicked.emit();
  }

  emailLogin(formData: { email: string, password: string, remember: boolean }){
    this.onEmailLogin.emit(formData);
  }

  facebookLogin(accessToken: string){
    this.onFacebookLogin.emit(accessToken)
  }

  googleLogin(accessToken: string){
    this.onGoogleLogin.emit(accessToken);
  }
}
