import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'alm-root-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss']
})
export class AuthContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
