<div class="error-snackbar-container" *ngIf="errorMessage">
  <div class="error-snackbar-container__message">
    <p>{{errorMessage | translate}}</p>
    <button class="btn btn-link btn-custom-link" *ngIf="action" (click)="action.callback()">{{action.text | translate}}</button>
  </div>
  <div class="error-snackbar-container__close">
    <button class="btn btn-icon" (click)="dismiss()">
      <svg aria-hidden="true">
        <use href="assets/images/sprite.svg#times"/>
      </svg>
    </button>
  </div>
</div>
