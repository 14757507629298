import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IEmailLogin} from "@interfaces/common/auth.interface";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";

@Component({
  selector: 'alm-root-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  loginFormCredentials: UntypedFormGroup;
  @Output() onForgetPasswordBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLogin: EventEmitter<IEmailLogin> = new EventEmitter<IEmailLogin>();
  private emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  @Input() loginIsClicked: boolean = false;

  constructor(private fb: UntypedFormBuilder, private gtmService: GoogleTagManagerService) {
    this.loginFormCredentials = this.fb.group({
      email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])),
      password: new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      rememberMe: new UntypedFormControl(true)
    });
  }

  ngOnInit(): void {
  }

  forgetPasswordClicked() {
    this.onForgetPasswordBtnClicked.emit();
  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required)
        return 1;
      if (fieldControl?.errors?.pattern?.requiredPattern == this.emailPattern)
        return 2;
      if (fieldControl?.errors?.minlength)
        return 3;
    }
    return 0;
  }

  login(){
    if(!this.getFormFieldError(this.loginFormCredentials.controls['email'])
      && !this.getFormFieldError(this.loginFormCredentials.controls['password'])){
      let loginFormData: IEmailLogin = {
        email: this.loginFormCredentials.controls['email'].value,
        password: this.loginFormCredentials.controls['password'].value,
        remember: this.loginFormCredentials.controls['rememberMe'].value
      };
      this.loginIsClicked = true;
      this.gtmService.sendLoginEvent({type: 'CLICK', status: 'success', loginType: ELoginTypes.EMAIL});
      this.onLogin.emit(loginFormData);
    }
    else {
      this.loginIsClicked = false;
    }
  }
}
