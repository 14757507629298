import {InjectionToken} from "@angular/core";
import {PlatformType} from "@interfaces/common/http.interface";
import {UserTypes} from '@interfaces/authorized-user/user.interface';

export enum StatusCode {
  OK = 200,

  REDIRECT_FOUND = 302,
  REDIRECT_MOVED_PERMANENTLY = 301,

  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  BAD_REQUEST = 400,

  INTERNAL_SERVER_ERROR = 500,
}

export const DEFAULT_HOME_PATH : string = "home";
export const DEFAULT_LOGIN_PATH : string = "login";
export const NOT_FOUND_PATH : string = "404";
export const PAYMENT_STATUS_PATH : string = "payment-status";

export const LEGACY_DIRECT_URL_HEADER = 'x-legacy-direct-url';

export const REQUESTER_APP_HEADER_NAME: string = 'Requester-App'
export const REQUESTER_APP_HEADER_VALUE: InjectionToken<PlatformType> = new InjectionToken<PlatformType>(
  'Injection token for setting the "Requester-App" header value',
  {
    providedIn: "root",
    factory: () => undefined
  });

export const USER_TYPE: InjectionToken<UserTypes> = new InjectionToken<UserTypes>(
  'Injection token for setting the "Requester-App" header value',
  {
    providedIn: "root",
    factory: () => UserTypes.Anonymous
  });

