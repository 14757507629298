import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {interval, Observable, Subscription, timer} from "rxjs";
import {take} from "rxjs/operators";

@Component({
  selector: 'alm-root-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  @Input() canSend: boolean = true;
  @Input() startTimerAt: number = 0;
  @Output() onResend: EventEmitter<any> = new EventEmitter<any>();
  timer: string = '02:00';
  private subscriptions: Subscription[] = [];
  private readonly maxTime: number = 119;

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.startTimerAt <= this.maxTime && this.startTimerAt > 0) {
      this.startTimer(this.startTimerAt);
    }
  }

  private startTimer(startAt: number){
    this.canSend = false;
    startAt = startAt > 0 && startAt <= this.maxTime ? startAt : this.maxTime;
    const timerInterval$ = interval(1000);
    let timerSub = timerInterval$.pipe(take(startAt)).subscribe((current) => {
      let countDownCurrent = startAt - current;
      let min = ('0' + parseInt(((countDownCurrent)/60).toString(), 10)).slice(-2);
      let sec = ('0' + (countDownCurrent)%60).slice(-2);
      this.timer = `${min}:${sec}`;
    });
    let  timeUpSub = timer(120000).subscribe(() => {
      this.canSend = true;
      this.startTimerAt = this.maxTime;
      this.timer = '02:00';
    })
    this.subscriptions.push(timerSub);
    this.subscriptions.push(timeUpSub);
  }

  resendActivation() {
    if (this.canSend) {
      this.onResend.emit();
      this.startTimer(this.maxTime);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => {
      subscription.unsubscribe();
    })
  }

}
