import { NgxTranslateCutOptions } from './alm-translate-cut.options.interface';
import {AlmTranslateCutOptionsService} from "./ngx-translate-cut.options.service";

export const almTranslateCutOptionsFactory = (
  options?: NgxTranslateCutOptions
): AlmTranslateCutOptionsService => {
  const ngxTranslateCutOptionsService = new AlmTranslateCutOptionsService();

  if (options) {
    if (options.separator) {
      ngxTranslateCutOptionsService.separator = options.separator;
    }
  }
  return ngxTranslateCutOptionsService;
};
