import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomContentService {
  private _hasCustomContent = new BehaviorSubject<boolean>(false);

  // Observable can be publicly exposed
  hasCustomContent$ = this._hasCustomContent.asObservable();

  // Method to update the value
  setHasCustomContent(value: boolean) {
    this._hasCustomContent.next(value);
  }
}