import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

/**
 * A service to manage dynamic loading of scripts and stylesheets
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicLoadService {

  private renderer2: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(PLATFORM_ID) private platformId: any) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  /**
   * Load External Scripts
   * @param id An Id for the script to not be duplicated
   * @param link The Source link for the script
   * @param afterScriptEvent Determine when to fire the event
   */
  externalScript(id: string, link: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (isPlatformBrowser(this.platformId)) {
        let firstScriptElement = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) {
          resolve(true);
        }
        let newScript = this.renderer2.createElement('script');
        this.renderer2.setAttribute(newScript, 'id', id);
        this.renderer2.setAttribute(newScript, 'src', link);
        this.renderer2.setAttribute(newScript, 'async', 'true');
        // this.renderer2.setAttribute(newScript, 'defer', 'true');
        this.renderer2.insertBefore(firstScriptElement.parentNode, newScript, firstScriptElement);
        this.renderer2.listen(newScript, 'load', () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }

    });
  }

  /**
   * Load External Stylesheets
   * @param id An Id for the stylesheet to not be duplicated
   * @param link The Source link for the stylesheet
   */
  externalStyleSheet(id: string, link: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (isPlatformBrowser(this.platformId)) {
        let headElement = document.getElementsByTagName('head')[0];
        if (document.getElementById(id)) return;
        let newStylesheet = this.renderer2.createElement('link');
        this.renderer2.setAttribute(newStylesheet, 'id', id);
        this.renderer2.setAttribute(newStylesheet, 'href', link);
        this.renderer2.setAttribute(newStylesheet, 'rel', 'stylesheet');
        this.renderer2.appendChild(headElement, newStylesheet);
        this.renderer2.listen(newStylesheet, 'load', () => {
          resolve(true);
        });
      } else {
        resolve(true);
      }

    });
  }
}
