import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FacebookAuthService} from "@services/social-auth/facebook-auth.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";

@Component({
  selector: 'alm-root-facebook-button',
  templateUrl: './facebook-button.component.html',
  styleUrls: ['./facebook-button.component.scss']
})
export class FacebookButtonComponent implements AfterViewInit {

  @Input() title: string = '';
  @Input() facebookId: string = '';
  @Output() onFacebookAccessTokenReceived: EventEmitter<string> = new EventEmitter<string>();

  constructor(private facebookService: FacebookAuthService, private uiLoader: UiLoaderService, private gtmService: GoogleTagManagerService) {
  }

  ngAfterViewInit(): void {
    this.facebookService.loadFBScript(this.facebookId).then(() => {
      this.facebookService.notSupportedBrowserFacebookLogin().then((accessToken: string) => {
        this.onFacebookAccessTokenReceived.emit(accessToken);
      }).catch((e) => {
        console.error('Facebook ERROR', e);
      });
    });
  }

  facebookLogin() {
    this.uiLoader.startUiLoader('social-loader');
    this.facebookService.getUserAccessTokenFromFacebook().then((accessToken: string) => {
      this.onFacebookAccessTokenReceived.emit(accessToken);
      this.uiLoader.stopUiLoader('social-loader');
    }).catch((e) => {
      this.uiLoader.stopUiLoader('social-loader');
      console.error('Facebook ERROR', e);
    });
  }
}
