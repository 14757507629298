import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {IUserType} from "@interfaces/authorized-user/user.interface";
import amplitude, {Identify} from 'amplitude-js';
import {isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  loadAmplitude(apiKey: string, userId?: string):void {
    /*if(isPlatformServer(this.platformId)) return;
    amplitude.getInstance().init(apiKey, userId, {
      disableCookies: true
    });*/
  }

  sendEvent(event: string, eventProps?: any) {
    /*if(isPlatformServer(this.platformId)) return;
    if(eventProps) {
      amplitude.getInstance().logEvent(event, eventProps);
    } else {
      amplitude.getInstance().logEvent(event);
    }*/
  }

  setUserProperty(userProperties: {User_Last_Order_Date?: string, 'User_Account_Type'?: string, Course_Content_Consumption?: string, User_Number_Of_Enrolled_Courses?:number, User_Payment_Method_Saved?: string}) {
    if(isPlatformServer(this.platformId)) return;
    if(userProperties.User_Last_Order_Date) {
      let userProperty = new amplitude.Identify().set('User_Last_Order_Date',userProperties.User_Last_Order_Date);
      amplitude.getInstance().identify(userProperty)
    }
    if(userProperties.User_Account_Type) {
      let userProperty = new amplitude.Identify().set('User_Account_Type',userProperties.User_Account_Type);
      amplitude.getInstance().identify(userProperty)
    }
    if(userProperties.Course_Content_Consumption) {
      let userProperty = new amplitude.Identify().set('Course_Content_Consumption',userProperties.Course_Content_Consumption);
      amplitude.getInstance().identify(userProperty)
    }
    if(userProperties.User_Number_Of_Enrolled_Courses) {
      let userProperty = new amplitude.Identify().set('User_Number_Of_Enrolled_Courses',userProperties.User_Number_Of_Enrolled_Courses);
      amplitude.getInstance().identify(userProperty)
    }
    if(userProperties.User_Payment_Method_Saved) {
      let userProperty = new amplitude.Identify().set('User_Payment_Method_Saved',userProperties.User_Payment_Method_Saved);
      amplitude.getInstance().identify(userProperty)
    }
  }

  signupStartedEvent(eventProps: {Signup_Started_Source?: string}) {
    this.sendEvent('Signup_Started', eventProps);
  }

  signupCompletedEvent(eventProps: {Signup_Completed_Account_Type: string}) {
    this.sendEvent('Signup_Completed', eventProps);
  }

  profileInfoEnteredEvent() {
    this.sendEvent("User_Profile_Information_Entered");
  }

  interestsSelectedEvent() {
    this.sendEvent("User_Interests_Selected");
  }

  subscriptionCompletedEvent(eventProps: {Subscription_Completed_Source?: string, Subscription_Completed_Plan_Type?: string, Subscription_Completed_Payment_Method: string, Subscription_Completed_Coupon_Discount_Percentage?: string}) {
    this.sendEvent('Subscription_Completed', eventProps);
  }

  coursePurchasedEvent(eventProps: {Course_Purchase_Number_Of_Courses?: number, Course_Purchase_Payment_Method?: string, Course_Purchase_Cart_Total?: number, Course_Purchase_Coupon_Discount_Percentage?: string}) {
    this.sendEvent("Course_Purchase", eventProps);
  }

  sponsoredCoursesEnrollmentEvent(eventProps: {Sponsored_Courses_Enrollment_Course_Name: string}) {
    this.sendEvent("Sponsored_Courses_Enrollment", eventProps);
  }

  subscriptionCanceledEvent(eventProps: {Subscription_Canceled_Plan_Type?: string, Subscription_Canceled_Cancellation_Reason: string}) {
    this.sendEvent("Subscription_Canceled", eventProps);
  }

  subscriptionPlanChangedEvent(eventProps: {Subscription_Plan_Changed_Current_Plan_Type?: string, Subscription_Plan_Changed_New_Plan_Type: string}) {
    this.sendEvent("Subscription_Plan_Changed", eventProps);
  }
  creditCardSavedEvent() {
    this.sendEvent("Credit_Card_Saved");
  }
  FavouriteCourseAddedToCartEvent() {
    this.sendEvent("Favourite_Course_Added_To_Cart");
  }

  courseSharedEvent(eventProps: { Course_Shared_Course_Name: string | undefined; Course_Shared_Platform: string }) {
    this.sendEvent('Course_Shared', eventProps);
  }

  certificateSharedEvent(eventProps: { Certificate_Shared_Course_Name: string | undefined; Certificate_Shared_Platform: string }) {
    this.sendEvent('Certificate_Shared', eventProps);
  }

  coursePlayedEvent(eventProps: { Course_Played_Course_Name?: string; Course_Played_Percentage_Of_Completion?: string }) {
   this.sendEvent('Course_Played', eventProps)
  }

  // courseDetailsPageViewedEvent(eventProps: { "page title"?: string; "source": string; "page url"?: string }) {
  //   this.sendEvent("Page viewed", eventProps)
  // }

  coursePromoPlayedEvent(eventProps: { Course_Promo_Played_Course_Name?:string }) {
    this.sendEvent('Course_Promo_Played', eventProps);
  }
  courseIntroPlayedEvent(eventProps: {Course_Intro_Played_Course_Name: string}) {
    this.sendEvent('Course_Intro_Played', eventProps);
  }
  lessonStarredEvent() {
    this.sendEvent('Course_Lesson_Starred');
  }
  noteTakenEvent(eventProps: {Course_Note_Taken_Course_Name: string, Course_Note_Taken_Note_Type: string}) {
    this.sendEvent('Course_Note_Taken', eventProps);
  }
  discussionCommentAddedEvent(eventProps: {Course_Discussion_Comment_Added_Course_Name: string, Course_Discussion_Comment_Added_Comment_Type: string}) {
    this.sendEvent('Course_Discussion_Comment_Added', eventProps);
  }

  askMentorEvent(eventProps: { Ask_Course_Mentor_Course_Name?: string }) {
    this.sendEvent('Ask_Course_Mentor', eventProps);
  }

  courseRatedEvent(eventProps: {Course_Rated_Course_Name?: string; Course_Rated_Overall_Rating_Value: number }) {
    this.sendEvent('Course_Rated', eventProps);
  }

  certificateGeneratedEvent(eventProps: { Certificate_Generated_Course_Name: string | undefined }) {
    this.sendEvent('Certificate_Generated', eventProps);
  }
  addToCartEvent(eventProps: {Course_Add_To_Cart_Source: string}) {
    this.sendEvent('Course_Add_To_Cart', eventProps);
  }
  themeChangedEvent(eventProps: {Website_Theme_Changed_Theme: string}) {
    this.sendEvent('Website_Theme_Changed', eventProps);
  }
  languageChangedEvent(eventProps: {Website_Language_Changed_Language: string}) {
    this.sendEvent('Website_Language_Changed', eventProps);
  }
  deviceKnockoutEvent() {
    this.sendEvent('Knockout_Device');
  }
}
