<div class="footer-business">
  <h5>{{'footer.footer_business_title' | translate}}</h5>
  <ul *ngIf="!isB2bUser" class="business-links">
    <li><a routerLink="/become-mentor">
      {{'footer.footer_business_links' | translate | translateCut: 0}}
    </a></li>
<!--    <li><a href="#">-->
<!--      {{'footer.footer_business_links' | translate | translateCut: 1}}-->
<!--    </a></li>-->
  </ul>
  <a href="https://business.almentor.net/" target="_blank"
     class="btn btn-outline-train">{{'footer.footer_business_train' | translate}}</a>
</div>
