import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplifiedHeaderComponent } from './simplified-header.component';
import {RouterModule} from "@angular/router";



@NgModule({
    declarations: [SimplifiedHeaderComponent],
    exports: [
        SimplifiedHeaderComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class SimplifiedHeaderModule { }
