import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from "ngx-cookie";
import {EncryptionService} from "@services/request-token/encryption.service";
import {USER_TYPE} from "@constants/http.constants";
import {
  EventUserProperties,
  SourcePageInfo,
  SourceTypes, TrackingEvents,
  TrackingMetaData,
  TrackingSuperProperties,
  UserClientType
} from '@services/tracking/tracking.interface';
import {environment} from "@environments/environment";
import {BehaviorSubject} from "rxjs";
import {
  ANON_USER_PROPERTIES,
  baseLinkCookieOptions,
  DEVICE_ID,
  GTM_SUPER_PROP_KEY,
  ONE_YEAR
} from '@constants/cookies.constants';
import {LanguageControlService} from "@services/language/language-control.service";
import {v4 as uuidv4} from 'uuid';
import {pageTypes} from "@services/tracking/page-types";
import {SharedSessionStorageService} from "@services/shared-session-storage/shared-session-storage.service";
import {REDIRECT} from "@constants/session-storage.constants";
import {NavigationTrackerService} from "@services/routing/navigation-tracker/navigation-tracker.service";
import {UserTypes} from "@interfaces/authorized-user/user.interface";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class TrackingDataService {
  private superProperties$ = new BehaviorSubject<Partial<TrackingSuperProperties>>({});
  private persistableSuperProperties: (keyof TrackingSuperProperties)[] = [
      'th_capi_em',
      'th_capi_fn',
      'user_country',
  ]
  private staticSuperProperties: Partial<TrackingSuperProperties> = Object.freeze({
    user_type: this.userType,
    platform: UserClientType.WEBSITE,
  });
  private metadata: Partial<TrackingMetaData> = {};
  private readonly staticMetaData: Partial<TrackingMetaData>;

  constructor(
    private cookieService: CookieService,
    private encryptionService: EncryptionService,
    private languageControl: LanguageControlService,
    private sharedSessionStorageService: SharedSessionStorageService,
    private navigationTrackerService: NavigationTrackerService,
    @Inject(USER_TYPE) private userType: UserTypes,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.staticMetaData  = Object.freeze({
        device_id: this.getDeviceId(),
        environment: environment.environmentName,
      });
      const storedSuperProperties = this.getStoredSuperProperties();
      this.setSuperProperties({
        ...storedSuperProperties,
        ...this.staticSuperProperties,
      });

      this.languageControl.currentLanguage.subscribe(language => {
        this.setSuperProperties({ language: language.name });
      });
    }
  }

  setEventMetaData(newMetaData: Partial<TrackingMetaData>) {
    this.metadata = {
      ...this.metadata,
      ...newMetaData,
      ...this.staticMetaData,
    }
  }

  get eventMetaData(): Partial<TrackingMetaData> {
    return {
      ...this.metadata,
      ...this.staticMetaData,
    }
  }

  get SuperProperties(): BehaviorSubject<Partial<TrackingSuperProperties>> {
    return this.superProperties$;
  }

  public setSuperProperties(properties: Partial<TrackingSuperProperties>) {
    const updatedProperties = {
      ...this.superProperties$.value,
      ...properties,
    };
    this.superProperties$.next(updatedProperties);
    this.persistSuperProperties(updatedProperties);
  }

  public resetSuperProperties() {
    this.cookieService.remove(GTM_SUPER_PROP_KEY, baseLinkCookieOptions);
    this.setSuperProperties(this.staticSuperProperties)
  }

  private getDeviceId() {
    const cookieDeviceId = this.cookieService.get(DEVICE_ID);
    if (cookieDeviceId) return cookieDeviceId;

    const newDeviceId = uuidv4();
    this.cookieService.put(DEVICE_ID, newDeviceId, {
      ...baseLinkCookieOptions,
      expires: new Date(Date.now() + ONE_YEAR),
    });
    return newDeviceId;
  }

  public resetDeviceId(): void {
    this.cookieService.remove(DEVICE_ID, baseLinkCookieOptions);
  }

  private persistSuperProperties(superProperties: object) {
    const propertiesToPersist = this.persistableSuperProperties.reduce((properties, key) => {
      properties[key] = superProperties[key];
      return properties;
    }, {});
    const encodedSuperProperties = this.encryptionService.encodeStringToBase64(JSON.stringify(propertiesToPersist));
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.cookieService.put(GTM_SUPER_PROP_KEY, encodedSuperProperties, {
      ...baseLinkCookieOptions,
      expires: cookieDate,
    });
  }
  private getStoredSuperProperties(): object {
    try {
      const encodedSuperProperties = this.cookieService.get(GTM_SUPER_PROP_KEY);
      const decodedSuperProperties = this.encryptionService.decodeBase64ToString(encodedSuperProperties);
      if (!decodedSuperProperties || decodedSuperProperties === "undefined") return {};
      return JSON.parse(decodedSuperProperties);
    } catch (error) {
      console.error('Error in getStoredSuperProperties()', error);
      return {};
    }
  }

  getSourcePageAttributes(sourceType: SourceTypes): SourcePageInfo {
    const source_page_url: string = this.getEventSourceURL(sourceType);

    const source_page_type = pageTypes.find((page) => {
      const supportedLanguages =  this.languageControl.allLanguageCodes.join('|');
      return source_page_url.match(`^(/(${supportedLanguages}))?${page.pattern}`);
    })?.Type || 'N/A';
    const source_page_name = this.getEventSourceTitle(sourceType);
    return {
      source_page_url,
      source_page_type,
      source_page_name,
    };
  }

  private getEventSourceURL(sourceType: SourceTypes): string {
    let urlPath: string;

    switch (sourceType) {
      case SourceTypes.AUTHENTICATION_SOURCE:
        urlPath = this.getAuthRedirectURL();
        break;
      case SourceTypes.CURRENT_PAGE:
        urlPath = this.navigationTrackerService.getCurrentNavigation().url;
        break;
        case SourceTypes.PREVIOUS_PAGE:
        urlPath = this.navigationTrackerService.getPreviousNavigation().url;
        break;
    }

    return urlPath || window.location.pathname;
  }

  private getAuthRedirectURL(): string | undefined {
    const redirectValue = this.sharedSessionStorageService.getItem(REDIRECT);
    if (!redirectValue) return undefined;

    try {
      const {route} = JSON.parse(redirectValue);
      return route;
    } catch (error) {
      console.error(`Error in getAutRedirectURL()`, error);
      return undefined;
    }

  }

  private getEventSourceTitle(sourceType: SourceTypes): string {
    switch (sourceType) {
      case SourceTypes.AUTHENTICATION_SOURCE:
        return 'N/A';
      case SourceTypes.CURRENT_PAGE:
        return this.navigationTrackerService.getCurrentNavigation().title;
      case SourceTypes.PREVIOUS_PAGE:
        return this.navigationTrackerService.getPreviousNavigation().title;
    }

  }

  storeAnonymousUserProperties<T extends keyof TrackingEvents>(user_properties?: EventUserProperties<T>): void {
    if (!user_properties) return;

    let trackedUserProperties: EventUserProperties<T> = this.getStoredAnonymousUserProperties<T>() || {} as EventUserProperties<T>;

    if (user_properties.set) {
      trackedUserProperties.set = { ...trackedUserProperties.set, ...user_properties.set };
    }

    if (user_properties.increment) {
      trackedUserProperties.increment = {
        ...trackedUserProperties.increment,
        ...Object.fromEntries(Object.entries(user_properties.increment).map(([key, incrementValue]) => [
          key, (trackedUserProperties.increment?.[key] || 0) + incrementValue
        ]))
      };
    }

    this.cookieService.put(
      ANON_USER_PROPERTIES,
      JSON.stringify(trackedUserProperties),
      {
        ...baseLinkCookieOptions,
        expires: new Date(Date.now() + ONE_YEAR),
      });
  }

  getStoredAnonymousUserProperties<T extends keyof TrackingEvents>(): EventUserProperties<T> | undefined {
    try {
      let storedPropertiesString: string = this.cookieService.get(ANON_USER_PROPERTIES);
      if (storedPropertiesString) {
        return JSON.parse(storedPropertiesString);
      }
    } catch (error) {
      return undefined
    }
  }

  removeStoredAnonymousUserProperties() {
    this.cookieService.remove(ANON_USER_PROPERTIES, baseLinkCookieOptions);
  }
}
