<div class="auth-content">

  <div class="auth-header">
    <h5 class="auth-title text-center text-capitalize">{{'demographics.page_title' | translate}}</h5>
  </div>

  <div class="auth-body demographics">
    <form action="" [formGroup]="demographicsForm">
      <p class="section-label">{{'demographics.subTitle' | translate | translateCut: 0}}</p>
<!--      <div class="row">-->
<!--        <div class="col-auto">-->
<!--          <mat-form-field class="form-group with-prefix select-form-field" appearance="outline">-->
<!--            <mat-select class="form-control"-->
<!--                        placeholder="{{'demographics.placeholders' | translate | translateCut: 0}}"-->
<!--                        formControlName="country"-->
<!--                        (selectionChange)="onCountrySelectionChange($event)">-->
<!--              <mat-option>-->
<!--                <ngx-mat-select-search (ngModelChange)="filterArrayByName($event, countries, filteredCountries)"-->
<!--                                       [formControl]="countryFilterCtrl"-->
<!--                                       [clearSearchInput]="true" [enableClearOnEscapePressed]="true"-->
<!--                                       [hideClearSearchButton]="true"-->
<!--                                       [placeholderLabel]="'demographics.country_search' | translate | translateCut: 0"-->
<!--                                       [noEntriesFoundLabel]="'demographics.country_search' | translate | translateCut: 1">-->
<!--                </ngx-mat-select-search>-->
<!--              </mat-option>-->
<!--              <mat-option *ngFor="let country of filteredCountries | async" [value]="country">-->
<!--                {{country.name}}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <div matPrefix class="prefix-icon">-->
<!--              <svg width="22" height="18">-->
<!--                <use href="assets/images/sprite.svg#globe"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <mat-error *ngIf="getFormFieldError(demographicsForm.controls.country) as message">-->
<!--              {{'demographics.local_errors' | translate | translateCut: message - 1}}-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->

<!--        <div class="col-auto">-->
<!--          <mat-form-field class="form-group with-prefix select-form-field" appearance="outline">-->
<!--            <mat-select class="form-control"-->
<!--                        placeholder="{{'demographics.placeholders' | translate | translateCut: 1}}"-->
<!--                        formControlName="city">-->
<!--              <mat-option>-->
<!--                <ngx-mat-select-search (ngModelChange)="filterArrayByName($event, cities, filteredCities)"-->
<!--                                       [formControl]="cityFilterCtrl"-->
<!--                                       [clearSearchInput]="true" [enableClearOnEscapePressed]="true"-->
<!--                                       [hideClearSearchButton]="true"-->
<!--                                       [placeholderLabel]="'demographics.city_search' | translate | translateCut: 0"-->
<!--                                       [noEntriesFoundLabel]="'demographics.city_search' | translate | translateCut: 1">-->
<!--                </ngx-mat-select-search>-->
<!--              </mat-option>-->
<!--              <mat-option *ngFor="let city of filteredCities | async" [value]="city">-->
<!--                {{city.name}}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
<!--            <div matPrefix class="prefix-icon">-->
<!--              <svg width="22" height="18">-->
<!--                <use href="assets/images/sprite.svg#location"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <mat-error *ngIf="getFormFieldError(demographicsForm.controls.city) as message">-->
<!--              {{'demographics.local_errors' | translate | translateCut: message - 1}}-->
<!--            </mat-error>-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->

      <alm-root-phone-input [required]="false" (onValidatePhone)="onMobileChange($event)"></alm-root-phone-input>

      <p class="section-label">{{'demographics.subTitle' | translate | translateCut: 1}}</p>
      <div class="form-group">
        <div class="btn-group btn-group-toggle w-100 radio-as-buttons gender-radio-options">
          <input type="radio" name="gender" id="gender-male" checked [value]="0"
                 formControlName="gender"/>
          <label class="btn btn-outline-tangerine" for="gender-male">
            <!--            <svg width="22" height="18">-->
            <!--              <use href="assets/images/sprite.svg#male" />-->
            <!--            </svg>-->
            {{'demographics.placeholders' | translate | translateCut: 3}}
          </label>
          <input type="radio" name="gender" id="gender-female" [value]="1"
                 formControlName="gender"/>
          <label class="btn btn-outline-tangerine" for="gender-female">
            <!--            <svg width="22" height="18">-->
            <!--              <use href="assets/images/sprite.svg#female" />-->
            <!--            </svg>-->
            {{'demographics.placeholders' | translate | translateCut: 4}}
          </label>
        </div>
      </div>

      <p class="section-label">{{'demographics.subTitle' | translate | translateCut: 2}}</p>
      <div class="row">
        <mat-form-field class="form-group birth-input" appearance="outline">
          <mat-select class="form-control" (valueChange)="checkDateInsertion()"
                      placeholder="{{'demographics.placeholders' | translate | translateCut: 5}}"
                      formControlName="day">
            <mat-option *ngFor="let day of days" [value]="day">
              {{day}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getFormFieldError(demographicsForm.controls.day) as message">
            {{'demographics.local_errors' | translate | translateCut: message - 1}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group birth-input" appearance="outline">
          <mat-select class="form-control"
                      placeholder="{{'demographics.placeholders' | translate | translateCut: 6}}"
                      formControlName="month" (selectionChange)="generateDays()">
            <mat-option *ngFor="let month of months; index as index" [value]="month">
              {{'demographics.all_months' | translate | translateCut: index}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getFormFieldError(demographicsForm.controls.month) as message">
            {{'demographics.local_errors' | translate | translateCut: message - 1}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-group birth-input" appearance="outline">
          <mat-select class="form-control"
                      placeholder="{{'demographics.placeholders' | translate | translateCut: 7}}"
                      formControlName="year" (selectionChange)="generateDays()">
            <mat-option *ngFor="let year of years" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="getFormFieldError(demographicsForm.controls.year) as message">
            {{'demographics.local_errors' | translate | translateCut: message - 1}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <button [disabled]="demographicsForm.invalid || demographicsForm.pristine"
                class="btn btn-danger w-100 mt-3 button-font"
                (click)="saveDemographicsInfo()"
        >{{'demographics.next' | translate}}</button>
      </div>
    </form>

  </div>
</div>
