<div class="footer-bottom">
  <div class="footer-bottom__container">
    <div class="sub-footer-items">
      <div class="sub-footer-item">
        © {{ today | date:'yyyy' }}        <a href="https://www.almentor.net">
        {{'footer.sub_footer_item' | translate | translateCut: 1}}
      </a>
      </div>
      <div class="sub-footer-item">
        <a href="https://about.almentor.net/terms-conditions" target="_blank">
          {{'footer.sub_footer_item' | translate | translateCut: 2}}
        </a></div>
      <div class="sub-footer-item">
        <a href="https://about.almentor.net/privacy-policy" target="_blank">
          {{'footer.sub_footer_item' | translate | translateCut: 3}}
        </a></div>
      <div class="sub-footer-item">
        <a href="https://support.almentor.net" target="_blank" class="help-center">
          {{'footer.sub_footer_item' | translate | translateCut: 4}}
        </a></div>
    </div>
    <div class="social-links-container" *ngIf="showSocial">
      <alm-root-footer-social-links *ngIf="!isB2bUser"></alm-root-footer-social-links>
      <alm-root-footer-social-links-b2b *ngIf="isB2bUser"></alm-root-footer-social-links-b2b>
    </div>
  </div>
</div>
