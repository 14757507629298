<div class="phone-input" *ngIf="selectedCountry" dir="ltr">
  <mat-form-field  appearance="outline" [ngClass]="inPaymentWebsite ? 'payment-phone-input form-group with-prefix no-rtl' : 'form-group with-prefix no-rtl'">
    <input [maxLength]="20" matInput type="tel" name="phoneNumber" [placeholder]="placeholder"
           (ngModelChange)="onPhoneChange()"
           [formControl]="phoneCtrl" mask="00 000 0000||00 000 0000 0||00 000 0000 00||00 000 0000 000 ||00 000 0000 0000"
           [validation]="false" [patterns]="maskPattern"
           class="form-control">
    <div matPrefix class="prefix-icon phone-input__prefix">
      <div class="phone-input__prefix-code">
        <mat-select [disableOptionCentering]="true" (selectionChange)="onCountrySelect($event)" [value]="selectedCountry">
          <mat-select-trigger>
            <div class="phone-input__prefix-selected-country" *ngIf="selectedCountryIndex >= 0">
              <div class="phone-input__prefix-country">
                <svg *ngIf="!inPaymentWebsite">
                  <use
                    [attr.href]="'assets/images/countries.svg#'+countries[selectedCountryIndex]?.code?.toLowerCase()"/>
                </svg>
                <span>+{{countries[selectedCountryIndex]?.phoneCode}}</span>
              </div>
            </div>
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search [placeholderLabel]="'change_mobile_number.search' | translate | translateCut:0"
                                   [noEntriesFoundLabel]="'change_mobile_number.search' | translate | translateCut:1"
                                   [clearSearchInput]="true"
                                   (ngModelChange)="filterArrayByName($event, countries, filteredCountries)"
                                   [formControl]="countryFilterCtrl"
                                   [enableClearOnEscapePressed]="true"
                                   [hideClearSearchButton]="true">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [dir]="dir" *ngFor="let country of filteredCountries | async" [value]="country">
            <div class="phone-input__prefix-country">
              <svg>
                <use [attr.href]="'assets/images/countries.svg#'+country.code.toLowerCase()"/>
              </svg>
              <span title="{{country?.name}}">{{country?.name}}</span>
            </div>
          </mat-option>
        </mat-select>
      </div>
      <hr class="phone-input__prefix-separator">
    </div>
        <mat-error *ngIf="getFormFieldError(phoneCtrl) as message">
          {{'change_mobile_number.errors' | translate | translateCut: message - 1}}
        </mat-error>
  </mat-form-field>
</div>
