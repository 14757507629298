import {HttpParams} from '@angular/common/http';

export interface IHttpResponse {
  success: boolean;
  data: any;
  error: IHttpError;
}

export interface IHttpError {
  code: number;
  message: string;
  status: number;
}

/**
 * This enum used to unify cluster Names across application.
 * it used after the api gateway base url
 * FullUrl: apiGatewayUrl + ClusterName + endpoint
 * @see `environment.apiGatewayUrl`
 */
export enum ClusterNames {
  JavaOrch = `orchestrator`,
  NewOrch = 'new-orchestrator',
  B2BOrch = 'b2b-orchestrator',
  AdminOrch = 'admin-orchestrator',
  Subscription = 'plan-svc',
  Order = 'order-svc',
}

export type ServiceUrls = Record<ClusterNames, string>;

export interface IRequestOptions {
  endpoint: string;
  sender: string;
  receiver: string;
  body: object;
  clusterName?: ClusterNames;
  skipOnSSR?: boolean
  queryParams?: HttpParams;
}

export interface IGetRequestOptions {
  endpoint: string;
  skipOnSSR?: boolean;
  queryParams?: HttpParams;
}


export type PlatformType = 'ANONYMOUS_WEB' | 'LOGIN_WEB' | 'B2C_WEB' | 'B2B_WEB' | 'SELF-ENROLL_WEB';
