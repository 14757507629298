<div class="auth-content signup-container">
  <div class="auth-header">
    <h5 class="auth-title text-center">{{'sign_up_container.page_title' | translate}}</h5>
    <p class="text-center">{{'sign_up_container.new_account' | translate}}</p>
  </div>
  <div class="auth-body">
    <ng-content></ng-content>
  </div>
  <div class="auth-footer">
    <p class="text-center have-account">{{'sign_up_container.have_account' | translate}}
      <button class="font-weight-bold btn btn-link link-underline login-button"
              (click)="loginButtonClicked()">{{'sign_up_container.login' | translate}}</button></p>
  </div>
</div>
