import {environment} from "@environments/environment";


export const USER_PREFERENCES_COOKIE_KEY = 'alm-user';

export const SUBSCRIPTION_INITIATOR = 'alm-sub-init';

export const ALGOLIA_ANONYMOUS_USER_TOKEN = 'alm-algolia-device-token';
export const LOCAL_SEARCH_HISTORY = 'Alm-LSH';

export const BASE_LINK_DOMAIN = environment.platforms.base.link;
export const BASE_LINK_PATH = '/';
export const BASE_LINK_SAMESITE = false;
export const BASE_LINK_SECURE = true;

export const baseLinkCookieOptions = Object.freeze({
  domain: BASE_LINK_DOMAIN,
  path: BASE_LINK_PATH,
  sameSite: BASE_LINK_SAMESITE,
  secure: BASE_LINK_SECURE,
});

export const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

export const GTM_SUPER_PROP_KEY: string = 'alm-gtm-global-prop';
export const DEVICE_ID: string = 'alm-device-uid';
export const ANON_USER_PROPERTIES: string = 'alm-sst-anon-properties';
