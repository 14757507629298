<div class="signup-form">
  <form action="" [formGroup]="signUpForm">
    <mat-form-field class="form-group with-prefix" appearance="outline">
      <div class="prefix-icon" matPrefix>
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#name"/>
        </svg>
      </div>
      <input matInput type="name" name="name"
             placeholder="{{'sign_up_container.placeholder' | translate | translateCut: 0}}"
             class="form-control" formControlName="name">
      <mat-error *ngIf="getFormFieldError(signUpForm.controls.name) as message">
        {{'signup_container.local_errors' | translate | translateCut: message - 1}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-group with-prefix" appearance="outline">
      <div class="prefix-icon" matPrefix>
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#email"/>
        </svg>
      </div>
      <input matInput type="email" name="email"
             placeholder="{{'sign_up_container.placeholder' | translate | translateCut: 1}}"
             class="form-control" formControlName="email">
      <mat-error *ngIf="getFormFieldError(signUpForm.controls.email) as message">
        {{'signup_container.local_errors' | translate | translateCut: message - 1}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-group with-prefix with-suffix" appearance="outline">
      <input #password matInput type="password" name="password" (ngModelChange)="checkPasswordStrength($event)"
             placeholder="{{'sign_up_container.placeholder' | translate | translateCut: 2}}"
             class="form-control" formControlName="password">
      <div class="prefix-icon" matPrefix>
        <svg width="22" height="22">
          <use href="assets/images/sprite.svg#password"/>
        </svg>
      </div>
      <div matSuffix class="suffix-icon">
        <svg width="22" height="18" (click)="password.type=password.type=='password'?'text':'password'">
          <use href="assets/images/sprite.svg#visibility" *ngIf="password.type=='password'"/>
          <use href="assets/images/sprite.svg#visibility-off" *ngIf="password.type=='text'"/>
        </svg>
      </div>
      <mat-error *ngIf="getFormFieldError(signUpForm.controls.password) as message">
        {{'signup_container.local_errors' | translate | translateCut: message - 1}}
      </mat-error>
    </mat-form-field>

    <div class="password-instructions-container"
         *ngIf="(signUpForm.controls.password.statusChanges | async) && !signUpForm.controls.password.valid">
      <alm-root-password-checker [password]="signUpForm.controls.password.value"></alm-root-password-checker>
    </div>

    <mat-checkbox class="font-italic signup-checkbox" formControlName="receiveOffers">
      {{'sign_up_container.receive_offers' | translate}}
    </mat-checkbox>

    <p class="text-center">
      <button (click)="signup()" [disabled]="signUpForm.invalid || signupIsClicked" class="btn btn-danger btn-signup w-100">
        <svg width="22" height="18">
          <use href="assets/images/sprite.svg#email"/>
        </svg>
        {{'sign_up_container.sign_up_method' | translate | translateCut: 2}}
      </button>
    </p>
  </form>
</div>
