import {Injectable} from '@angular/core';
import {UAParser} from "ua-parser-js";
import {Router} from "@angular/router";

/**
 * An abstracted service for social authorization
 */
@Injectable({
  providedIn: 'root'
})
export abstract class SocialAuthService {

  protected constructor() {
  }

  /**
   * Check if the current browser is supported or not i.e(Facebook in app browser)
   */
  protected isSocialPopupSupportedBrowser = (): boolean => {
    let ua = new UAParser();
    let os = ua.getOS();
    if (os.name?.toLowerCase() == "android" || os.name?.toLowerCase() == "ios") {
      let supportedBrowsers: (string | undefined)[] = [
        'chrome',
        'firefox',
        'safari',
        'mobile safari'
      ];
      let browser = ua.getBrowser();
      if (!supportedBrowsers.includes(browser.name?.toLowerCase())) {
        return false;
      }
    }
    return true;
  };

  protected isITPBrowser = (): boolean => {
    let ua = new UAParser();
    let os = ua.getOS();
    let browser = ua.getBrowser();
    let itpBrowsers: (string | undefined)[] = [
      'firefox',
      'safari',
      'mobile safari'
    ];
    return itpBrowsers.includes(browser.name?.toLowerCase()) || (os.name?.toLowerCase() == "ios" || os.name?.toLowerCase() == "mac os" && browser.name?.toLowerCase() == 'chrome');

  }

  /**
   * Check if user tried a social login in a not supported browser
   */
  protected hasUserTriedSocialLoginInNotSupportedBrowser = (): string | null => {
    let socialProvider = localStorage.getItem('sp');
    if (socialProvider) {
      return socialProvider;
    }
    return null;
  };

}
