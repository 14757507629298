import { Pipe, PipeTransform } from '@angular/core';
import { SEPARATOR } from './alm-translate-cut.constants';
import {AlmTranslateCutOptionsService} from "./ngx-translate-cut.options.service";

@Pipe({
  name: 'translateCut',
})
export class AlmTranslateCutPipe implements PipeTransform {
  constructor(private options?: AlmTranslateCutOptionsService) {}

  public transform(value: string, index: number): string {
    const cutIndex = Number(index);
    const splitted: string[] | null = value ? value.split(this.options?.separator || SEPARATOR) : null;
    const phrase: string | null = splitted ? splitted[cutIndex] : null;
    const result = phrase ? phrase.trim() : '';

    return result;
  }
}
