import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {IInterest} from '@interfaces/common/auth.interface';
import {UserService} from "@services/integrations/user/user.service";
import {BrazeService} from '@services/marketing/braze/braze.service';
import {UserHttpService} from '@services/integrations/user/user-http.service';

@Component({
  selector: 'alm-root-interests-container',
  templateUrl: './interests-container.component.html',
  styleUrls: ['./interests-container.component.scss']
})
export class InterestsContainerComponent implements OnInit {

  interests: IInterest [] = [];
  selectedInterestsIds: Set<number> = new Set<number>();
  @Output() sendSelectedInterests: EventEmitter<number[]> = new EventEmitter<number[]>();
  private enInterests: IInterest[] = [];

  constructor(private languageControl: LanguageControlService, private userService: UserService,
              private brazeService: BrazeService,
              private httpUser: UserHttpService,
              ) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    this.userService.getAllInterestsByLanguageId('interests-container').then(allInterests => {
      this.interests = allInterests;
      this.enInterests = allInterests;
      const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
      if (currentLanguageId === 2) {
        this.httpUser.getAllInterestsByLanguageId('settings-interests', 1).then( enInterests => {
          this.enInterests = enInterests;
        });
      }
    });
  }

  onSelect(interest: IInterest) {
    let isSelectedInterest = this.selectedInterestsIds.has(interest.rankedTagId);
    if (isSelectedInterest) {
      this.selectedInterestsIds.delete(interest.rankedTagId)
    } else {
      this.selectedInterestsIds.add(interest.rankedTagId);
    }
  }

  saveUserInterests() {
    let selectedInterests: number[] = Array.from(this.selectedInterestsIds);
    if (selectedInterests.length > 0) {
      this.sendSelectedInterests.emit(selectedInterests);
      const brazeInterests = this.enInterests.filter( interest => selectedInterests.includes(interest.rankedTagId));
      this.brazeService.setCustomUserAttribute('interests', brazeInterests.map(i => i.name));
    }
  }
}
