import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IPaymentMethod, IPaypalResponse, PaymentMethods} from '@interfaces/common/payment.interface';
import {
  AggregatePaymentMethod,
  DcbConfirmOtpRequest,
  ICreditCardInfo,
  IPaymentBase,
  IVisaPayment,
  PaymentDetails,
  PaymentResponse,
  SubscriptionPaymentRequest
} from '@interfaces/payment/payment.interface';
import {OrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {ClusterNames} from '@interfaces/common/http.interface';

@Injectable({
  providedIn: 'root'
})
export class CheckoutHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  pay(checkoutObject: IPaymentBase): Promise<any> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.NewOrch,
      endpoint: 'paymentAdapter/api/PaymentAdapter/pay',
      sender: 'checkout',
      receiver: 'pay',
      body: checkoutObject
    });
  }

  subscriptionPayment<T extends PaymentDetails<AggregatePaymentMethod>>(paymentRequest: SubscriptionPaymentRequest<T>): Promise<PaymentResponse<T>> {
    return this.customHttpService.newSendRequest({
      endpoint: 'order-svc/order',
      sender: 'checkout',
      receiver: 'subscription-payment',
      body: paymentRequest
    });
  }

  payB2b(paymentMethod: number, checkoutObject?: IVisaPayment): Promise<any> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.B2BOrch,
      endpoint: 'b2bPaymentAdapter/api/PaymentAdapter/pay',
      sender: 'checkout',
      receiver: 'pay',
      body: {
        paymentMethod: paymentMethod,
        ...checkoutObject
      }
    });
  }

  subscribeWithPaypal(checkoutObject: IPaymentBase): Promise<IPaypalResponse> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/paypal/initialize',
      sender: 'checkout',
      receiver: 'initialize',
      body: checkoutObject
    });
  }

  activatePaypalSubscription(orderInfo: {trn: string, paypalSubscriptionId: string, paypalOrderId: string}): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/paypal/activate/subscription',
      sender: 'checkout',
      receiver: 'subscription',
      body: orderInfo
    });
  }

  changePaypalSubscriptionStatus(trn: string, status: number, paypalOrderId?: string): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/paypal/status',
      sender: 'checkout',
      receiver: 'status',
      body: {
        trn: trn,
        status: status,
        paypalId: paypalOrderId
      }
    });
  }

  getExistingCards(sender: string): Promise<ICreditCardInfo []> {
    return this.customHttpService.sendRequest( {
      clusterName: ClusterNames.NewOrch,
      endpoint: 'getUser/api/payfort/users/paymentCards',
      sender: sender,
      receiver: 'paymentCards',
      body: {},
    });
  }

  setDefaultCard(sender: string, cardToken: string, cardNumber: string): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest( {
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/payfort/cards/set-default',
      sender: sender,
      receiver: 'set-default',
      body: {
        token: cardToken,
        cardNumber: cardNumber
      }
    })
  }

  getOrderDetails(orderId: string): Promise<OrderDetails> {
    return this.customHttpService.getRequest({
      endpoint: `order-svc/${orderId}/details`,
    });
  }

  dcbResendOTPConfirmation(orderId: string): Promise<OrderDetails> {
    return this.customHttpService.newSendRequest({
      endpoint: `order-svc/order/${orderId}/resend-pin`,
      sender: 'sender',
      receiver: 'receiver',
      body: {},
    });
  }

  getPaymentMethodsByCountryId(sender:string, languageId:number): Promise<{paymentMethodIds: PaymentMethods[]}> {
    return this.customHttpService.sendRequest( {
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/paymentMethods/byCountryId',
      sender: sender,
      receiver: 'payment-methods',
      body: {
        languageId: languageId
      }
    })
  }

  deleteSavedCard(sender: string, cardNumber: string, token: string):Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      clusterName: ClusterNames.JavaOrch,
      endpoint: 'api/payfort/delete/card',
      sender: sender,
      receiver: 'delete-saved-cards',
      body: {
        cardNumber: cardNumber,
        token: token
      }
    })
  }

  dcbConfirmOtp(confirmOtpRequest: DcbConfirmOtpRequest) {
    return this.customHttpService.newSendRequest({
      endpoint: `payment-svc/order/${confirmOtpRequest.orderId}/confirm`,
      sender: 'sender',
      receiver: 'delete-saved-cards',
      body: {
        pin: confirmOtpRequest.pin
      }
    });
  }
}
