<header class="header">
  <div class="header-bg">
    <div class="login__header header-container container">
      <div class="col header-logo">
        <a class="header-logo_icon" (click)="navigateToAnonymousPage()">
          <h1>
            <span class="sr-only">Almentor</span>
            <svg>
              <use href="assets/images/sprite.svg#logo"/>
            </svg>
          </h1>
        </a>
      </div>
      <div class="login__header__user-controls" *ngIf="nextLanguage" [dir]="currentLanguage.id == 2 ? 'ltr' : 'rtl'">
        <button class="btn icon-item" (click)="onThemeChange()">
          <span class="sr-only">{{'user_control_menu.light_mode'}}</span>
          <svg>
            <use href="assets/images/sprite.svg#theme"/>
          </svg>
        </button>
        <button class="btn icon-item" (click)="onLanguageChange(nextLanguage.code)">
          <svg *ngIf="currentLanguage.id === 2">
            <use href="assets/images/sprite.svg#en"/>
          </svg>
          <svg *ngIf="currentLanguage.id === 1">
            <use href="assets/images/sprite.svg#ar"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</header>
