import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'alm-root-social-sign-up',
  templateUrl: './social-sign-up.component.html',
  styleUrls: ['./social-sign-up.component.scss']
})
export class SocialSignUpComponent implements OnInit {

  @Input() facebookId: string = '';
  @Input() googleId: string = '';
  @Input() redirectUrl: string = '';
  @Output() onEmailSignupBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTermsBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFacebookSignup: EventEmitter<string> = new EventEmitter<string>();
  @Output() onGoogleSignup: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  facebookLogin(accessToken: string){
    this.onFacebookSignup.emit(accessToken);
  }

  googleLogin(accessToken: string){
    this.onGoogleSignup.emit(accessToken);
  }

  emailSignupButtonClicked() {
    this.onEmailSignupBtnClicked.emit();
  }
}
