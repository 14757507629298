import {Injectable} from '@angular/core';
import {AuthHttpV2Service} from '@services/integrations/auth-v2/auth-http-v2.service';
import {IEmailSignup} from '@interfaces/common/auth.interface';
import {
  IActivationResponse,
  IChangePasswordPayload,
  IFacebookLoginInfoResponse, IFacebookLoginResponse, IGoogleLoginResponse,
  INewLoginResponse, ISignupResponse
} from '@interfaces/user/user-common.interface';
import {IDevice} from '@interfaces/common/device.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {SentryService} from "@services/sentry/sentry.service";
import {TrackingDataService} from "@services/tracking/tracking-data.service";

export interface IAuthorizedResponse {
  privilege: string,
  isLogged: boolean,
  forceLogout: boolean,
  isReachedMaxDevices: boolean,
  isUserBlockAll: boolean,
  platform: string,
  isFisrtLogin: boolean,
  firstAuthAfterSubscription: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthV2Service {

  constructor(private authV2Http: AuthHttpV2Service,
              private sentryService: SentryService,
              private trackingDataService: TrackingDataService,
              ) {
  }

  emailSingUp(sender: string, signUpForm: IEmailSignup): Promise<ISignupResponse> {
    return this.authV2Http.emailSingUp(sender, signUpForm);
  }

  activateUser(sender: string, v: number, g: string): Promise<IActivationResponse> {
    return this.authV2Http.activateUser(sender, v, g);
  }

  resendEmail(sender: string, email: string, password: string): Promise<any> {
    return this.authV2Http.resendEmail(sender, email, password);
  }

  emailLogin(sender: string, email: string, password: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return this.authV2Http.b2cEmailLogin(sender, email, password, platform, subDomain);
  }

  forgetPassword(email: string): Promise<{ result: boolean }> {
    return this.authV2Http.forgetPassword(email);
  }

  resetPassword(resetPasswordBody: { a: string, s: string, newPassword: string }): Promise<{ result: boolean }> {
    return this.authV2Http.resetPassword(resetPasswordBody);
  }

  getFacebookInfo(sender: string, accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return this.authV2Http.getFacebookInfo(sender, accessToken);
  }

  facebookLogin(sender: string, facebookInfo: IFacebookLoginInfoResponse, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    return this.authV2Http.facebookLogin(sender, facebookInfo, platform, subDomain);
  }

  googleLogin(sender: string, accessToken: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return this.authV2Http.googleLogin(sender, accessToken, platform, subDomain);
  }

  googleLoginWithCode(sender: string, code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    return this.authV2Http.googleLoginWithCode(sender, code, redirectUrl, platform, subDomain);
  }

  getUserDevices(userInfo: { email: string, password: string, userType: number } | {}): Promise<IDevice[]> {
    return this.authV2Http.getUserDevices(userInfo);
  }

  sendPinCode(sender: string, isResend: boolean, receiver?: string,
              userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ result: boolean }> {
    let sendPinCodeObject: any = {
      isResend: isResend
    };
    if (userInfo) {
      sendPinCodeObject['userData'] = {...userInfo};
    } else {
      sendPinCodeObject['reciever'] = receiver;
    }
    return this.authV2Http.sendPinCode(sender, sendPinCodeObject);
  }

  verifyEmailPinCode(sender: string, activationCode: number,
                     userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ isValid: boolean }> {
    let verifyCodeObject: {
      pinCode: number,
      userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}
    } = {
      pinCode: activationCode
    };
    if (userInfo) {
      verifyCodeObject['userData'] = {...userInfo};
    }
    return this.authV2Http.verifyEmailPinCode(sender, verifyCodeObject);
  }

  removeUserDevice(device: IDevice, userInfo?: { email: string, password: string }
    | { accessToken: string } | {}): Promise<{ result: boolean }> {
    let removeDeviceBody = {};
    if (userInfo) {
      removeDeviceBody = {
        deviceId: device.deviceId,
        ...userInfo
      };
    } else {
      removeDeviceBody = {
        deviceId: device.deviceId,
      };
    }
    return this.authV2Http.removeUserDevice(removeDeviceBody);
  }

  authorize(sender: string, platform: string | null, subDomain: string | null): Promise<IAuthorizedResponse> {
    return this.authV2Http.authorize(sender, platform, subDomain);
  }

  addNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.authV2Http.addNotificationToken(sender, token);
  }

  removeNotificationToken(sender: string, token: string): Promise<{ result: boolean }> {
    return this.authV2Http.removeNotificationToken(sender, token);
  }

  logUserOut(sender: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authV2Http.logoutUser(sender, null).then(() => {
        resolve(true);
        this.sentryService.setUser(null);
        this.trackingDataService.resetSuperProperties();
        this.trackingDataService.resetDeviceId();
      }).catch(() => {
        reject();
      });
    });
  }

  getUserInfo(sender: string): Promise<IUserInfo> {
    return this.authV2Http.getUserInfo(sender);
  }

  changeUserPassword(sender: string, payload: IChangePasswordPayload): Promise<{ result: boolean }> {
    return this.authV2Http.changeUserPassword(sender, payload);
  }
}
