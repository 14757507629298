import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'alm-root-info-snack-bar',
  templateUrl: './info-snack-bar.component.html',
  styleUrls: ['./info-snack-bar.component.scss']
})
export class InfoSnackBarComponent implements OnInit {


  message?: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: { info: string }, private _snackRef: MatSnackBarRef<InfoSnackBarComponent>) {
  }

  ngOnInit(): void {
    this.message = this.data.info;
  }

  dismiss() {
    this._snackRef.dismiss();
  }

}
