export enum EDeviceType {
  COMPUTER = "Computer",
  MOBILE = "Mobile",
  TABLET = "Tablet",
  UNKNOWN = "Unknown"
}

export interface IDevice {
  deviceId: string;
  deviceType: EDeviceType;
  deviceBrand: string;
  mainUserTypeUUID: string;
  isCurrentDevice: boolean;
}
