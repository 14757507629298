import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiLoaderComponent } from './ui-loader.component';
import {NgxUiLoaderModule, NgxUiLoaderRouterModule} from "ngx-ui-loader";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    UiLoaderComponent
  ],
  exports: [
    UiLoaderComponent
  ],
    imports: [
        CommonModule,
        NgxUiLoaderModule.forRoot({
          pbColor: "#ff2d17",
          minTime: 1500,
          maxTime: 4000,
        }),
        NgxUiLoaderRouterModule,
        MatProgressSpinnerModule
    ]
})
export class UiLoaderModule { }
