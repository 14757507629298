import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from "@services/language/language-control.service";

@Component({
  selector: 'alm-root-sign-up-container',
  templateUrl: './sign-up-container.component.html',
  styleUrls: ['./sign-up-container.component.scss']
})
export class SignUpContainerComponent implements OnInit {

  @Output() onLoginBtnClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

  loginButtonClicked() {
    this.onLoginBtnClicked.emit();
  }
}
