import {IPrivileges, IUserPrivileges} from '@interfaces/privileges/privileges.interface';

export const privileges: IPrivileges = {
  functions: {
    '000E': 'homePage'
  },
  actions: {
    '00': 'access',
    '01': 'create',
    '02': 'edit',
    '03': 'delete'
  }
}

export const allowAllPrivileges: IUserPrivileges = {
  website: {access: true, create: true, edit: true, delete: true},
  homePage: {access: true, create: true, edit: true, delete: true},
}
export const blockAllPrivileges: IUserPrivileges = {
  website: {access: false, create: false, edit: true, delete: false},
  homePage: {access: false, create: false, edit: false, delete: false}
}

export interface IRouterPrivileges {
  [key: string]: {
    privileges?: { function: string, action: string },
    children?: IRouterPrivileges
  }
}

export const routePrivileges: IRouterPrivileges = {};
