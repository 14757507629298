export enum EGender {
  Male,
  Female
}

export interface IEmailLogin {
  email: string;
  password: string;
  remember: boolean;
}

export interface IEmailSignup {
  userName: string;
  email: string;
  password: string;
  sendOffers?: boolean;
  platform?: string,
}

export interface IDemographics {
  gender: EGender;
  birthDate: string | undefined;
  countryId?: number;
  cityId?: number;
  mobileNumber: string;
}

export interface ICountry {
  id: number;
  name: string;
  code: string;
  phoneCode: string;
}

export interface ICountryTranslated {
  id: number,
  enTitle: string,
  arTitle: string
}

export interface ICity {
  id: number;
  name: string;
}

export interface IInterest {
  rankedTagId: number;
  name: string;
  isSelected: boolean;
}
export interface IInitiative {
  id: number;
  title: string;
  description: string;
  link: string;
  cardImg: string;
}
