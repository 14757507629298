import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {IHttpError} from "@interfaces/common/http.interface";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {LanguageControlService} from "@services/language/language-control.service";
import {UserService} from "@services/integrations/user/user.service";
import {SnackBarControlService} from "@services/snack-bar/snack-bar-control.service";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";

enum AuthViews {
  verify,
  demographics,
  interests,
  close
}

@Component({
  selector: 'alm-login-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.scss']
})
export class VerifyEmailDialogComponent implements OnInit {

  currentView: AuthViews = AuthViews.verify;
  sendAfterOpen: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private viewData: any, private dialogService: DialogService,
              private languageControl: LanguageControlService, private authV2Service: AuthV2Service, private uiLoader: UiLoaderService,
              private userService: UserService, private snackBarControl: SnackBarControlService) {
  }

  ngOnInit(): void {
    if (this.viewData) {
      this.currentView = this.viewData.type;
      this.sendAfterOpen = this.viewData.sendAfterOpen;

      if (this.sendAfterOpen) {
        this.resendActivation();
      }
    }
  }

  closeDialog() {
    this.dialogService.closeDialog('verify-email')
  }

  resendActivation(): void {
    this.uiLoader.startUiLoader('http-loader');
    if (this.viewData.email && this.viewData.password && this.currentView == AuthViews.verify) {
      this.authV2Service.resendEmail('verify', this.viewData.email, this.viewData.password).catch((e: IHttpError) => {
        this.snackBarControl.openHttpErrorSnackbar(e.code);
      }).finally(() => {
        this.uiLoader.stopUiLoader('http-loader');
      });
    }
  }
}
