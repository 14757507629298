import {Injectable} from '@angular/core';
import {
  ConversionStrings,
  IConversionSource,
  IHeroBanner
} from '@interfaces/marketing/marketing.interface';
import {MarketingHttpService} from '@services/integrations/marketing/marketing-http.service';
import {IAdOrderDetails, IOrderDetails, OrderDetails, OrderStatus} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {SubscriptionMarketingService} from '@services/marketing/subscription-marketing.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {environment} from '@environments/environment';
import {Params} from '@angular/router';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {BrazeSubscriptionEventsService} from '@services/marketing/braze/events/braze-subscription-events.service';
import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';
import {EncryptionService} from '@services/request-token/encryption.service';
import {CookieService} from "ngx-cookie";
import {SentryService} from "@services/sentry/sentry.service";
import {EPaymentStatusId, IPaymentStatus} from "@interfaces/payment-website/payment-status.interface";
import {TrackingService} from "@services/tracking/tracking.service";
import {LanguageId} from "@interfaces/common/language.interface";
import {PAYMENT_ORDERS} from "@constants/session-storage.constants";
import {CheckoutHttpService} from '@services/integrations/checkout/checkout-http.service';

const CONVERSION_MAX_DAYS = 11;

@Injectable({
  providedIn: 'root'
})
export class MarketingService {

  conversionDomain = environment.platforms.base.link;

  constructor(private marketingHttpService: MarketingHttpService, private brazeService: BrazeService,
              private gtmService: GoogleTagManagerService,
              private subscriptionMarketingService: SubscriptionMarketingService,
              private encryptionService: EncryptionService,
              private cookieService: CookieService,
              private sentryService: SentryService,
              private trackingService: TrackingService,
              private checkoutHttpService: CheckoutHttpService,
              ) {
  }

  reportOrderStatus(orderId: string, orderDetails: OrderDetails) {
    const isAlreadyReported = this.isOrderInSession(orderId, orderDetails);
    if (isAlreadyReported) return;
    this.saveOrderInSession(orderId, orderDetails);

    let paymentMethod: IPaymentMethod = orderDetails.payment.paymentMethod;
    let coupon = orderDetails.payment.couponCode ?? undefined;
    let item = {
      id: orderDetails.plan.planId,
      name: orderDetails.plan.planNameEn,
      coupon: coupon,
    };
    this.sentryService.captureMessage(`${paymentMethod}: Payment status ${orderDetails.order.orderStatus}`);
    if (orderDetails.order.orderStatus === 'Paid') {
      this.gtmService.successfulPaymentStatus({
        trn: orderId,
        price: orderDetails.pricing.priceInUsd,
        currency: 'USD',
        discount: orderDetails.pricing.originalPriceInUsd - orderDetails.pricing.priceInUsd,
        item: item,
        method: paymentMethod
      });
    } else if (orderDetails.order.orderStatus === 'PendingUserAction') {
      this.brazeService.logEvent('payment_pending');
      this.gtmService.pendingPaymentStatus({
        method: paymentMethod,
        trn: orderId,
        item: item
      })
    } else {
      this.brazeService.logEvent('payment_failed');
      this.gtmService.failedPaymentStatus({
        method: paymentMethod,
        trn: orderId,
        item: item,
      });
    }
    this.ReportOrderStatusToServerSideTracking(orderDetails);
  }

  getAdUserOrderDetails(sender: string): void {
    this.marketingHttpService.getAdUSerOrder(sender).then((order) => {
      this.createPlatformConversionCookie({
        adSourceType: order.adSourceType,
        adSourceId: order.adSourceId,
        createdAt: order.adCreatedAt,
      });
      if (order.orderId) {
        this.checkoutHttpService.getOrderDetails(order.orderId).then((orderDetails) => {
          this.reportOrderStatus(order.orderId, orderDetails);
        });
      }
    }).catch((e) => {
    })
  }

  getUserAd(sender: string): void {
    this.marketingHttpService.getUserAd(sender).then((userAd) => {
      const  processAdSourceContent = (adSourceId: string) => {
        if (this.encryptionService.isValidBase64(userAd.adSourceId)) {
          return  JSON.parse(this.encryptionService.decodeBase64ToString(userAd.adSourceId));
        } else {
          return adSourceId;
        }
      }
      if (userAd.adSourceId) {
        let processedAd: IConversionSource = {
          ...userAd,
          adSourceId: processAdSourceContent(userAd.adSourceId)
        };
        this.createPlatformConversionCookie(processedAd);
      }
    }).catch((e) => {
    })
  }

  checkForAdConversionCookie(): string | null {
    const conversionCookie = this.cookieService.get('conversion');
    if (conversionCookie) {
      return conversionCookie;
    } else {
      return null;
    }
  }

  deleteAdConversionCookie(): void {
    this.cookieService.remove('conversion', {
      domain: environment.platforms.base.link,
      path: '/',
      sameSite: "none",
      secure: true,
    });
  }

  createAdConversionCookie(routeParams: Params): void {
    let conversion: IConversionSource | undefined = this.getConversionFromURLQueryParams(routeParams);
    if (conversion) {
      let cookieDate = new Date();
      cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
      this.addCookie({
        name: 'conversion',
        value: JSON.stringify(conversion),
        domain: this.conversionDomain,
        expires: cookieDate,
      });
      this.createPlatformConversionCookie(conversion);
    }
  }

  createUTMCookie(utmQueryParams: string) {
    if (!utmQueryParams) return;
    let cookieDate = new Date();
    cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
    this.addCookie({
      name: 'alm-utm',
      value: `?${utmQueryParams}`,
      domain: this.conversionDomain,
      expires: cookieDate,
    });
  }

  createPlatformConversionCookie(conversionSource: IConversionSource) {
    let cookieDate = conversionSource.createdAt ? new Date(conversionSource.createdAt) : new Date();
    cookieDate.setDate(cookieDate.getDate() + CONVERSION_MAX_DAYS);
    let clickId = '';
    let utmQueryParams = '';
    // TODO we will use string to support legacy adSourceId string after one month of deployment we can remove string checks
    if ( typeof conversionSource.adSourceId === 'string') {
      clickId = conversionSource.adSourceId
    } else {
      clickId = conversionSource.adSourceId?.clickId || '';
      utmQueryParams = conversionSource.adSourceId?.utmParams
    }
    switch (conversionSource.adSourceType) {
      case 'facebook':
        this.addCookie({
          name: '_fbc',
          value: `fb.1.${new Date().getTime()}.${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
      return;
      case 'google':
        this.addCookie({
          name: '_gcl_aw',
          value: `GCL.${Math.floor(new Date().getTime() / 1000)}.${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
        return;
      case 'tiktok':
        this.addCookie({
          name: 'ttclid',
          value: `${clickId}`,
          domain: this.conversionDomain,
          expires: cookieDate,
        });
        this.createUTMCookie(utmQueryParams);
        return;
      case 'utmOnlyCampaign':
        this.createUTMCookie(utmQueryParams);
        return;
    }
  }

  addCookie({name, value, domain, expires}: { name: string, value: string, domain: string, expires: Date}) {
    this.cookieService.put(name, value, {
      domain,
      expires,
      path: '/',
      secure: true,
      sameSite: false
    });
  }

  addUserFromAd(sender: string, conversionSource: IConversionSource): Promise<{ result: boolean }> {
    const adSourceContent = this.encryptionService.encodeStringToBase64(JSON.stringify(conversionSource.adSourceId))
    return this.marketingHttpService.addUserFromAd(sender,conversionSource.adSourceType, adSourceContent);
  }

  private getConversionFromURLQueryParams(params: Params): IConversionSource | undefined {
    if (params[ConversionStrings.google]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.google],
          utmParams: new URLSearchParams(params).toString(),
        },
        adSourceType: 'google',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.facebook]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.facebook],
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'facebook',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.tiktok]) {
      return {
        adSourceId: {
          clickId: params[ConversionStrings.tiktok],
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'tiktok',
        createdAt: new Date().toISOString(),
      }
    } else if (params[ConversionStrings.utmOnlyCampaign]) {
      return {
        adSourceId: {
          utmParams: new URLSearchParams(params).toString()
        },
        adSourceType: 'utmOnlyCampaign',
        createdAt: new Date().toISOString(),
      }
    } else {
      return undefined
    }
  }

  getHeroBanner(languageId: LanguageId): Promise<IHeroBanner> {
    return this.marketingHttpService.getHeroBanner(languageId);
  }

  private isOrderInSession(orderId: string, order: OrderDetails): boolean {
    const orders = sessionStorage.getItem(PAYMENT_ORDERS);
    if (!orders) return false;

    try {
      const ordersMap = JSON.parse(orders);
      return ordersMap[orderId] === order.order.orderStatus;
    } catch (e) {
      console.error('Error parsing orders from sessionStorage', e);
      return false;
    }
  }

  private saveOrderInSession(orderId: string, order: OrderDetails) {
    let ordersMap: Record<string, OrderStatus>;

    try {
      const orders = sessionStorage.getItem(PAYMENT_ORDERS);
      ordersMap = orders ? JSON.parse(orders) : {};
    } catch {
      ordersMap = {};
    }

    ordersMap[orderId] = order.order.orderStatus;

    sessionStorage.setItem(PAYMENT_ORDERS, JSON.stringify(ordersMap));
  }

  private ReportOrderStatusToServerSideTracking(orderDetails: OrderDetails) {
    this.trackingService.sendEvent('payment_completed', {
      event_properties: {
        payment_status: this.subscriptionMarketingService.getPaymentStatus(orderDetails.order.orderStatus),
        subscription_type: orderDetails.order.orderType,
        coupon_code: orderDetails.payment.couponCode ?? undefined,
        coupon_code_added: !!orderDetails.payment.couponCode,
        selected_payment_method: orderDetails.pricing.finalPrice > 0 ? orderDetails.payment.paymentMethod: 'FREE',
        selected_subscription_plan_currency: orderDetails.pricing.currency,
        selected_subscription_plan_price: orderDetails.pricing.finalPrice,
        selected_subscription_plan_type: orderDetails.plan.planNameEn,
        selected_subscription_plan_id: orderDetails.plan.planId,
      }
    });
  }
}
