<div class="ui-loader-container">
  <ngx-ui-loader [fgsSize]="50" [fgsTemplate]="uiLoaderBackgroundSpinner"></ngx-ui-loader>
  <ng-template #uiLoaderBackgroundSpinner>
<!--    <div class="ui-loader-container__logo-container" id="master-ui-loader">-->
<!--      <svg class="ui-loader-container__logo" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path id="Path_4132" fill="#eb2027"-->
<!--              d="M-496.8 276.076a11.212 11.212 0 0 0-2.41-3.575 11.2 11.2 0 0 0-3.574-2.41 11.179 11.179 0 0 0-4.378-.884 11.175 11.175 0 0 0-4.378.884 11.194 11.194 0 0 0-3.575 2.41 11.2 11.2 0 0 0-2.41 3.575 11.175 11.175 0 0 0-.884 4.378 11.178 11.178 0 0 0 .884 4.378 11.2 11.2 0 0 0 2.41 3.575 11.212 11.212 0 0 0 3.575 2.41 10.958 10.958 0 0 0 1.145.412l.4 6.049 2.877-4.086 2.918-4.176a9.07 9.07 0 0 1-2.963.494 9.033 9.033 0 0 1-3.389-.653 9.037 9.037 0 0 1-3.013-2 9 9 0 0 1-2.653-6.4 8.995 8.995 0 0 1 2.653-6.4 8.995 8.995 0 0 1 6.4-2.652 9 9 0 0 1 6.4 2.652 9 9 0 0 1 2.652 6.4 9 9 0 0 1-2.652 6.4 9.13 9.13 0 0 1-1.758 1.373l-2.246 3.214a11.189 11.189 0 0 0 1.978-.628 11.22 11.22 0 0 0 3.575-2.41 11.221 11.221 0 0 0 2.41-3.575 11.177 11.177 0 0 0 .884-4.378 11.174 11.174 0 0 0-.878-4.377z"-->
<!--              transform="translate(518.411 -269.207)"/>-->
<!--        <path id="Path_4133"-->
<!--              d="M-497.684 285.229l7.448 4.563a.268.268 0 0 1 0 .456l-8.065 4.964a.268.268 0 0 1-.409-.228V285.1a.268.268 0 0 1 .4-.232z"-->
<!--              transform="translate(506.375 -278.755)"/>-->
<!--      </svg>-->
<!--    </div>-->
  </ng-template>
</div>
<div class="ui-loader-container" id="add-cart-loader">
  <ngx-ui-loader [fgsTemplate]="addCartULoaderBackgroundSpinner" [loaderId]="'add-to-cart-loader'"></ngx-ui-loader>
  <ng-template #addCartULoaderBackgroundSpinner>
<!--    <div class="ui-loader-container__logo-container">-->
<!--      <div class="ui-loader-container__logos">-->
<!--        <svg class="ui-loader-container__logo ui-loader-container__logo-almentor" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path id="Path_4132" fill="#eb2027"-->
<!--                d="M-496.8 276.076a11.212 11.212 0 0 0-2.41-3.575 11.2 11.2 0 0 0-3.574-2.41 11.179 11.179 0 0 0-4.378-.884 11.175 11.175 0 0 0-4.378.884 11.194 11.194 0 0 0-3.575 2.41 11.2 11.2 0 0 0-2.41 3.575 11.175 11.175 0 0 0-.884 4.378 11.178 11.178 0 0 0 .884 4.378 11.2 11.2 0 0 0 2.41 3.575 11.212 11.212 0 0 0 3.575 2.41 10.958 10.958 0 0 0 1.145.412l.4 6.049 2.877-4.086 2.918-4.176a9.07 9.07 0 0 1-2.963.494 9.033 9.033 0 0 1-3.389-.653 9.037 9.037 0 0 1-3.013-2 9 9 0 0 1-2.653-6.4 8.995 8.995 0 0 1 2.653-6.4 8.995 8.995 0 0 1 6.4-2.652 9 9 0 0 1 6.4 2.652 9 9 0 0 1 2.652 6.4 9 9 0 0 1-2.652 6.4 9.13 9.13 0 0 1-1.758 1.373l-2.246 3.214a11.189 11.189 0 0 0 1.978-.628 11.22 11.22 0 0 0 3.575-2.41 11.221 11.221 0 0 0 2.41-3.575 11.177 11.177 0 0 0 .884-4.378 11.174 11.174 0 0 0-.878-4.377z"-->
<!--                transform="translate(518.411 -269.207)"/>-->
<!--          <path id="Path_4133"-->
<!--                d="M-497.684 285.229l7.448 4.563a.268.268 0 0 1 0 .456l-8.065 4.964a.268.268 0 0 1-.409-.228V285.1a.268.268 0 0 1 .4-.232z"-->
<!--                transform="translate(506.375 -278.755)"/>-->
<!--        </svg>-->
<!--        <svg class="ui-loader-container__logo ui-loader-container__logo-cart" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M7.542 19.041a2.014 2.014 0 1 0 2.014 2.014 2.011 2.011 0 0 0-2.014-2.014zm10.07 0a2.014 2.014 0 1 0 2.014 2.014 2.011 2.011 0 0 0-2.014-2.014zm-9.9-3.273l.03-.121.906-1.641h7.5a2 2 0 0 0 1.762-1.037l3.89-7.06-1.752-.967h-.01l-1.107 2.015-2.779 5.035H9.083l-.131-.272L6.7 6.957l-.96-2.014-.947-2.014H1.5v2.014h2.014l3.625 7.643-1.359 2.467a1.947 1.947 0 0 0-.252.967 2.02 2.02 0 0 0 2.014 2.014h12.084V16.02H7.965a.256.256 0 0 1-.252-.252z"-->
<!--                transform="translate(-1.5 -2.929)"></path>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
  </ng-template>
</div>

