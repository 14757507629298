<div class="container" mat-dialog-content>
  <div class="dialog-verify-email-header">
    <div class="dialog-close">
      <button type="button" (click)="closeDialog()">
        <svg aria-hidden="true">
          <use href="assets/images/sprite.svg#times"/>
        </svg>
      </button>
    </div>
  </div>
  <div class="dialog-verify-email-content">
    <alm-login-verify-email (onResend)="resendActivation()"></alm-login-verify-email>
  </div>
</div>
