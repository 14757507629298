import { Injectable } from '@angular/core';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {ELoginTypes, IUserInfo} from '@interfaces/authorized-user/user.interface';
import {ISignupData} from "@interfaces/user/user-common.interface";
import {BrazeEvents} from "@services/marketing/braze/braze-constants";

@Injectable({
  providedIn: 'root'
})
export class BrazeUserEventsService {

  constructor(
    private brazeService: BrazeService,
  ) { }

  logUserinfo(loginInfo: IUserInfo): void {
      this.brazeService.setEmail(loginInfo.email);
      this.brazeService.setName(loginInfo.name);
  }

  sendBrazeSignUpEvent(activateData: ISignupData, loginType: ELoginTypes) {
    this.brazeService.initBraze();
    this.brazeService.setUserId(activateData.uuid);
    if (activateData.email) {
      this.brazeService.setEmail(activateData.email);
    }
    this.brazeService.logEvent(BrazeEvents.SIGN_UP, {
      user_name: activateData.userName,
      signup_method: loginType,
    });
    this.brazeService.immediateDataFlush();
  }
}
