import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordCheckerComponent } from './password-checker.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [PasswordCheckerComponent],
  exports: [
    PasswordCheckerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class PasswordCheckerModule { }
