import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';

@Component({
  selector: 'alm-root-password-checker',
  templateUrl: './password-checker.component.html',
  styleUrls: ['./password-checker.component.scss']
})
export class PasswordCheckerComponent implements OnInit, OnChanges {

  @Input() password: string = '';
  passwordStrength = {
    capitalLetter: {
      id: 1,
      pattern: /[A-Z]/,
      checked: false
    },
    smallLetter: {
      id: 2,
      pattern: /[a-z]/,
      checked: false
    },
    specialCharacter: {
      id: 3,
      pattern: /[-!@#$%^&*_~+/.]/,
      checked: false
    },
    number: {
      id: 4,
      pattern: /[0-9]/,
      checked: false
    },
    count: {
      id: 5,
      min: 8,
      max: 20,
      checked: false
    },
  };

  constructor(private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
  }

  checkPasswordStrength() {
    this.passwordStrength.capitalLetter.checked = this.passwordStrength.capitalLetter.pattern.test(this.password);
    this.passwordStrength.smallLetter.checked = this.passwordStrength.smallLetter.pattern.test(this.password);
    this.passwordStrength.specialCharacter.checked = this.passwordStrength.specialCharacter.pattern.test(this.password);
    this.passwordStrength.number.checked = this.passwordStrength.number.pattern.test(this.password);
    this.passwordStrength.count.checked = this.password.length >= this.passwordStrength.count.min
      && this.password.length <= this.passwordStrength.count.max;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkPasswordStrength();
  }

}
