import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {DialogService} from "@services/custom-dialogs/dialog.service";

@Component({
  selector: 'alm-root-add-email-dialog',
  templateUrl: './add-email-dialog.component.html',
  styleUrls: ['./add-email-dialog.component.scss']
})
export class AddEmailDialogComponent implements OnInit {

  emailField: UntypedFormControl;
  private emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';

  constructor(private languageControl: LanguageControlService, private dialogService: DialogService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.emailField = new UntypedFormControl('',
      Validators.compose([Validators.required, Validators.pattern(this.emailPattern)]));
  }

  ngOnInit(): void {
  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required) {
        return 1;
      }
      if (fieldControl?.errors?.pattern?.requiredPattern == this.emailPattern ||
        fieldControl?.errors?.minlength) {
        return 2;
      }
    }
    return 0;
  }

  onConfirm() {
    if (this.emailField.valid) {
      this.dialogService.closeDialog('add-email', {email: this.emailField.value});
    }
  }
}
