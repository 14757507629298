import {Injectable} from '@angular/core';
import {
  IFacebookLoginInfoResponse,
  IFacebookLoginResponse,
  IGoogleLoginResponse,
  INewLoginResponse,
  ISignupResponse
} from "@interfaces/user/user-common.interface";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {DialogService} from "@services/custom-dialogs/dialog.service";
import {AddEmailDialogComponent} from "@components/add-email-dialog/add-email-dialog.component";
import {DeviceManagementDialogComponent} from "@components/device-management-dialog/device-management-dialog.component";
import {IDevice} from "@interfaces/common/device.interface";
import {
  VerificationInputDialogComponent
} from "@components/verification-input-dialog/verification-input-dialog.component";
import {SnackBarControlService} from "@services/snack-bar/snack-bar-control.service";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {environment} from "@environments/environment";
import {IEmailSignup} from "@interfaces/common/auth.interface";
import {BrazeUserEventsService} from "@services/marketing/braze/events/braze-user-events.service";
import {ELoginTypes, UserTypes} from "@interfaces/authorized-user/user.interface";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {TrackingService} from "@services/tracking/tracking.service";
import {lastValueFrom} from "rxjs";
import {EUserType} from "@interfaces/common/connector.interface";
import {TrackingDataService} from "@services/tracking/tracking-data.service";
import {SourceTypes} from "@services/tracking/tracking.interface";

interface IUserCredentials {
  email: string,
  password: string,
  remember: boolean
}

enum ELoginType {
  EMAIL = 1,
  FACEBOOK,
  GOOGLE,
}

interface ILoginInfo {
  type: ELoginType,
  platform: string | null,
  subDomain: string | null,
  userCredentials?: IUserCredentials,
  accessToken?: string,
  code?: string,
  redirectUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthControlV2Service {

  platformCode: string | null = null;
  subDomain: string | null = null;

  constructor(private authService: AuthV2Service, private dialogService: DialogService,
              private snackbarControl: SnackBarControlService, private uiLoader: UiLoaderService,
              private connectorV2Service: ConnectorV2Service,
              private brazeUserEventsService: BrazeUserEventsService,
              private gtmService: GoogleTagManagerService,
              private trackingService: TrackingService,
              private trackingDataService: TrackingDataService,
  ) {
  }

  facebookLogin(accessToken: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.FACEBOOK,
        platform: this.platformCode,
        subDomain: this.subDomain,
        accessToken: accessToken
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  googleLogin(accessToken: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.GOOGLE,
        platform: this.platformCode,
        subDomain: this.subDomain,
        accessToken: accessToken
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  googleLoginWithCode(code: string, redirectUrl: string): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.GOOGLE,
        platform: this.platformCode,
        subDomain: this.subDomain,
        code: code,
        redirectUrl: redirectUrl
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  emailLogin(userCredentials: IUserCredentials): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let loginInfo: ILoginInfo = {
        type: ELoginType.EMAIL,
        platform: this.platformCode,
        subDomain: this.subDomain,
        userCredentials: userCredentials
      };
      this.loginProcess(loginInfo, 1).then(resolve).catch(reject);
    });
  }

  emailSignup(signupForm: IEmailSignup): Promise<ISignupResponse>{
    return new Promise<ISignupResponse>((resolve, reject) => {
      if (!this.platformCode && !this.subDomain) {
        this.platformCode = environment.platforms.b2c.code;
      }
      let signupInfo: IEmailSignup = {
        ...signupForm,
        platform: this.platformCode ?? undefined
      };
      this.authService.emailSingUp('sign-up', signupInfo).then(resolve).catch(reject);
    });
  }

  private getFacebookInfo(accessToken: string): Promise<IFacebookLoginInfoResponse> {
    return new Promise<IFacebookLoginInfoResponse>((resolve, reject) => {
      this.authService.getFacebookInfo('account', accessToken)
        .then((facebookInfo) => {
          if (facebookInfo.email) {
            resolve(facebookInfo);
          } else {
            this.uiLoader.stopUiLoader('login-process');
            this.addEmailDialog().then((email) => {
              resolve({
                email: email,
                ...facebookInfo
              });
            }).catch(reject);
          }
        })
        .catch(reject)
    });
  }

  private verifyEmailLogin(userCredentials: IUserCredentials, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    const sourceAttributes = this.trackingDataService.getSourcePageAttributes(SourceTypes.AUTHENTICATION_SOURCE);
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.authService.emailLogin('account', userCredentials.email, userCredentials.password, platform, subDomain)
        .then((loginRes) => {
          this.trackingService.sendEvent('user_login_completed', {
            event_properties: {
              last_login_date: new Date().toISOString(),
              login_method: ELoginTypes.EMAIL,
              user_organization: loginRes.organizationName || undefined,
              user_type: this.getUserType(loginRes),
              ...sourceAttributes,
            },
            user_properties: {
              set: {
                last_login_date: new Date().toISOString(),
                user_organization: loginRes.organizationName || undefined,
              }
            }
          });
          setTimeout(() => {
            resolve(loginRes);
          }, 200);
        }).catch(reject);
    });
  }

  private verifyFacebookLogin(accessToken: string, platform: string | null, subDomain: string | null): Promise<IFacebookLoginResponse> {
    const sourceAttributes = this.trackingDataService.getSourcePageAttributes(SourceTypes.AUTHENTICATION_SOURCE);
    return new Promise<IFacebookLoginResponse>((resolve, reject) => {
      this.getFacebookInfo(accessToken).then((facebookInfo) => {
        this.uiLoader.startUiLoader('login-process');
        this.authService.facebookLogin('account', facebookInfo, platform, subDomain)
          .then((facebookLoginResponse: IFacebookLoginResponse) => {
            if (facebookLoginResponse.isLoginFirstTime) {
              this.gtmService.sendSignupEvent({type: 'RESPONSE', status: 'success', loginType: ELoginTypes.FACEBOOK});
              this.brazeUserEventsService.sendBrazeSignUpEvent(facebookLoginResponse, ELoginTypes.FACEBOOK);
              this.trackingService.sendEvent('user_sign_up_completed', {
                event_properties: {
                  registration_date: facebookLoginResponse.registrationDate,
                  registration_method: ELoginTypes.FACEBOOK,
                  user_id: facebookLoginResponse.email,
                },
                user_properties: {
                  set: {
                    registration_date: facebookLoginResponse.registrationDate,
                    registration_method: ELoginTypes.FACEBOOK,
                  },
                }
              });
              this.trackingService.sendEvent('user_account_activated', {
                event_properties: undefined,
                user_properties: {
                  set: {
                    user_id: facebookLoginResponse.email,
                    activation_date: facebookLoginResponse.registrationDate,
                  },
                }
              });
            } else {
              this.trackingService.sendEvent('user_login_completed', {
                event_properties: {
                  last_login_date: new Date().toISOString(),
                  login_method: ELoginTypes.FACEBOOK,
                  user_organization: facebookLoginResponse.organizationName || undefined,
                  user_type: this.getUserType(facebookLoginResponse),
                  ...sourceAttributes,
                },
                user_properties: {
                  set: {
                    last_login_date: new Date().toISOString(),
                    user_organization: facebookLoginResponse.organizationName || undefined,
                  },
                }
              });
            }
            setTimeout(() => {
              resolve(facebookLoginResponse);
            }, 200);
          }).catch(reject);
      }).catch(reject);
    });
  }

  private loginProcess(loginInfo: ILoginInfo, count: number = 1): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.uiLoader.startUiLoader('login-process');
      this.verifyLoginInfo(loginInfo).then((verifyResponse) => {
        if (verifyResponse) {
          this.connectorV2Service.isCheckingAuthorization = true;
          this.logUserIn(verifyResponse).then(resolve).catch(reject);
        } else {
          reject();
        }
      }).catch((e) => {
        this.uiLoader.stopUiLoader('login-process');
        reject(e);
      });
    });
  }

  private verifyGoogleLogin(accessToken: string, platform: string | null, subDomain: string | null): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.authService.googleLogin('account', accessToken, platform, subDomain)
        .then((loginRes) => {
          resolve(loginRes);
        }).catch(reject);
    });
  }

  private verifyGoogleLoginWithCode(code: string, redirectUrl: string, platform: string | null, subDomain: string | null): Promise<IGoogleLoginResponse> {
    const sourceAttributes = this.trackingDataService.getSourcePageAttributes(SourceTypes.AUTHENTICATION_SOURCE);
    return new Promise<IGoogleLoginResponse>((resolve, reject) => {
      this.authService.googleLoginWithCode('account', code, redirectUrl, platform, subDomain)
        .then((googleLoginResponse: IGoogleLoginResponse) => {
          if (googleLoginResponse.isLoginFirstTime) {
            this.gtmService.sendSignupEvent({type: 'RESPONSE', status: 'success', loginType: ELoginTypes.GOOGLE});
            this.brazeUserEventsService.sendBrazeSignUpEvent(googleLoginResponse, ELoginTypes.GOOGLE);
            this.trackingService.sendEvent('user_sign_up_completed', {
              event_properties: {
                registration_date: googleLoginResponse.registrationDate,
                registration_method: ELoginTypes.GOOGLE,
                user_id: googleLoginResponse.email,
              },
              user_properties: {
                set: {
                  registration_date: googleLoginResponse.registrationDate,
                  registration_method: ELoginTypes.GOOGLE,
                }
              }
            });
            this.trackingService.sendEvent('user_account_activated', {
              event_properties: undefined,
              user_properties: {
                set: {
                  user_id: googleLoginResponse.email,
                  activation_date: googleLoginResponse.registrationDate,
                }
              }
            });
          } else {
            this.trackingService.sendEvent('user_login_completed', {
              event_properties: {
                last_login_date: new Date().toISOString(),
                login_method: ELoginTypes.GOOGLE,
                user_organization: googleLoginResponse.organizationName || undefined,
                user_type: this.getUserType(googleLoginResponse),
                ...sourceAttributes
              },
              user_properties: {
                set: {
                  last_login_date: new Date().toISOString(),
                  user_organization: googleLoginResponse.organizationName || undefined,
                }
              }
            });
          }
          setTimeout(() => {
            resolve(googleLoginResponse);
          }, 200);
        }).catch(reject);
    });
  }


  private verifyLoginInfo(loginInfo: ILoginInfo): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      if (loginInfo.type === ELoginType.EMAIL && loginInfo.userCredentials) {
        this.verifyEmailLogin(loginInfo.userCredentials, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.FACEBOOK && loginInfo.accessToken) {
        this.verifyFacebookLogin(loginInfo.accessToken, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.GOOGLE && loginInfo.accessToken) {
        this.verifyGoogleLogin(loginInfo.accessToken, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else if (loginInfo.type === ELoginType.GOOGLE && loginInfo.code && loginInfo.redirectUrl) {
        this.verifyGoogleLoginWithCode(loginInfo.code, loginInfo.redirectUrl, loginInfo.platform, loginInfo.subDomain).then(resolve).catch(reject);
      } else {
        reject();
      }
    });
  }

  private removeDevice(loginInfo: ILoginInfo): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.removeDevicePopup(loginInfo).then((device) => {
        this.verifyEmailPopup(loginInfo, device).then((isRemoved) => {
          if (isRemoved) {
            resolve(isRemoved);
          } else {
            reject();
          }
        }).catch(reject);
      }).catch(reject);
    });
  }

  private removeDevicePopup(loginInfo: ILoginInfo): Promise<IDevice> {
    return new Promise<IDevice>((resolve, reject) => {
      lastValueFrom(this.dialogService.openDialog(DeviceManagementDialogComponent, 'device-manage',
        loginInfo).afterClosed()).then(device => {
        if (device) {
          resolve(device);
        } else {
          reject();
        }
      });
    });
  }

  private verifyEmailPopup(loginInfo: ILoginInfo, device: IDevice): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      lastValueFrom(this.dialogService.openDialog(VerificationInputDialogComponent, 'verification-input',
        {
          removeDevice: true,
          device: device,
          ...loginInfo
        }, {disableClose: true}).afterClosed()).then(result => {
        if (result?.deviceRemoved) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  private logUserIn(loginResponse: INewLoginResponse): Promise<INewLoginResponse> {
    return new Promise<INewLoginResponse>((resolve, reject) => {
      this.snackbarControl.closeAllSnackBars();
      this.connectorV2Service.addUser({
        uuid: loginResponse.uuid,
        type: loginResponse.platformName ? EUserType.B2B : EUserType.B2C,
        link: loginResponse.platformName ? `https://${loginResponse.platformName.toLowerCase()}.${environment.platforms.base.link}` : null
      }).finally(() => {
        this.uiLoader.stopUiLoader('login-process');
        resolve(loginResponse);
      });
    });
  }

  private addEmailDialog(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      lastValueFrom(this.dialogService.openDialog(AddEmailDialogComponent, 'add-email', {}, {
        disableClose: true,
        closeOnNavigation: false
      }).afterClosed()).then((emailData: { email: string }) => {
        if (emailData?.email) {
          resolve(emailData.email);
        } else {
          reject();
        }
      });
    });
  }

  private getUserType(loginResponse: INewLoginResponse): UserTypes {
    if (!!loginResponse.organizationName) {
      return UserTypes.Business;
    } else {
      return UserTypes.Consumer;
    }
  }
}
