import {Injectable} from '@angular/core';
import {DynamicLoadService} from "@services/dynamic-load/dynamic-load.service";
import {SocialAuthService} from "@services/social-auth/social-auth.service";

/**
 * A deceleration of the facebook sdk enrty point
 */
declare var FB: any;

/**
 * A service to get handle the facebook authorization
 */
@Injectable({
  providedIn: 'root'
})
export class FacebookAuthService extends SocialAuthService {

  private fb: any;

  constructor(private dynamicLoadService: DynamicLoadService) {
    super();
  }

  /**
   * Load the Facebook sdk
   * @param facebookId
   */
  loadFBScript(facebookId: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.fb)
        resolve(true);
      this.dynamicLoadService.externalScript('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js')
        .then((loaded: boolean) => {
          this.initFB(facebookId).then(() => {
            this.fb = FB;
            resolve(true);
          });
        });
    });
  }

  /**
   * Initialize the Facebook SDK
   * @param facebookId
   * @private
   */
  private initFB(facebookId: string): Promise<any> {
    return new Promise<void>(resolve => {
      // @ts-ignore
      window['fbAsyncInit'] = () => {
        FB.init({
          appId: facebookId,
          cookie: true,
          version: 'v9.0'
        });
        FB.AppEvents.logPageView();
        resolve();
      };
    });
  }

  /**
   * Get the user access token from facebook if user is logged in facebook and authorized with our application before
   * @private
   */
  private getUserAccessTokenWhenUserAuthorized(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.fb.getLoginStatus((response: any) => {
        if (response.status == "connected") {
          resolve(response.authResponse.accessToken);
        } else {
          reject();
        }
      }, true);
    });
  }

  /**
   * Open the facebook login dialog so the user can authorize with our application
   * @private
   */
  private getUserAccessTokenWhenUserNotAuthorized(): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      this.fb.login((response: any) => {
        if (response.authResponse) {
          resolve(response.authResponse.accessToken);
        } else {
          reject();
        }
      }, {scope: 'email,public_profile', enable_profile_selector: true});
    });
  }

  /**
   * Get user facebook access token and save the process for the case of not supported browser
   */
  getUserAccessTokenFromFacebook(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!this.isSocialPopupSupportedBrowser()) {
        localStorage.setItem('sp', 'FACEBOOK');
      }
      this.getUserAccessTokenWhenUserNotAuthorized()
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * Handle the case of not supported browsers
   * *Should be called after facebook app initialized
   */
  notSupportedBrowserFacebookLogin(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let userTry = this.hasUserTriedSocialLoginInNotSupportedBrowser();
      if (userTry && userTry == 'FACEBOOK') {
        localStorage.removeItem('sp');
        this.getUserAccessTokenWhenUserAuthorized()
          .then(resolve)
          .catch(reject);
      }
    });
  }
}
