import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IEmailSignup} from "@interfaces/common/auth.interface";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {BrazeService} from '@services/marketing/braze/braze.service';

@Component({
  selector: 'alm-root-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {

  @Output() onEmailSignup: EventEmitter<IEmailSignup> = new EventEmitter<IEmailSignup>();
  @Input() signupIsClicked: boolean = false;

  signUpForm: UntypedFormGroup;
  private namePattern = '^[a-zA-Z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF_.\'-]+(([ ][a-zA-Z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF_.\'-])?[a-zA-Z\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF_.\'-]*)*$';
  private emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  private passwordPattern = '^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*_~+/.])\\S{8,20}$';
  constructor(private fb: UntypedFormBuilder, private gtmService: GoogleTagManagerService) {
    this.signUpForm = this.fb.group({
      name: new UntypedFormControl('', Validators.compose([Validators.required,
        Validators.pattern(this.namePattern)])),
      email: new UntypedFormControl('', Validators.compose([Validators.required,
        Validators.pattern(this.emailPattern)])),
      password: new UntypedFormControl('', Validators.compose([Validators.required,
        Validators.minLength(8), Validators.pattern(this.passwordPattern)])),
      receiveOffers: [true]
    });
  }

  ngOnInit(): void {
  }

  checkPasswordStrength(password: string) {

  }

  getFormFieldError(fieldControl: AbstractControl | null): number {
    if (fieldControl?.touched) {
      if (fieldControl?.errors?.required)
        return 1;
      if (fieldControl?.errors?.pattern?.requiredPattern == this.namePattern)
        return 2;
      if (fieldControl?.errors?.pattern?.requiredPattern == this.emailPattern)
        return 3;
      if (fieldControl?.errors?.minlength || fieldControl?.errors?.pattern?.requiredPattern == this.passwordPattern)
        return 4;
    }
    return 0;
  }

  signup() {
    if (!this.getFormFieldError(this.signUpForm.controls['name']) && !this.getFormFieldError(this.signUpForm.controls['email'])
      && !this.getFormFieldError(this.signUpForm.controls['password'])) {
      this.signupIsClicked = true;
      let signupFormData: IEmailSignup = {
        userName: this.signUpForm.controls['name'].value,
        email: this.signUpForm.controls['email'].value,
        password: this.signUpForm.controls['password'].value
      };
      this.gtmService.sendSignupEvent({type: 'CLICK', status: 'success'});
      this.onEmailSignup.emit(signupFormData);
    }
    else {
      this.signupIsClicked = false;
    }
  }
}
