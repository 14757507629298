import {Component, OnInit} from '@angular/core';
import {DialogService} from "@services/custom-dialogs/dialog.service";

@Component({
  selector: 'alm-root-device-management-dialog',
  templateUrl: './device-management-dialog.component.html',
  styleUrls: ['./device-management-dialog.component.scss']
})
export class DeviceManagementDialogComponent implements OnInit {

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogService.closeDialog('device-manage');
  }

}
