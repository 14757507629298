import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleButtonComponent } from './google-button/google-button.component';
import { FacebookButtonComponent } from './facebook-button/facebook-button.component';



@NgModule({
  declarations: [GoogleButtonComponent, FacebookButtonComponent],
  exports: [
    GoogleButtonComponent,
    FacebookButtonComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SocialButtonsModule { }
