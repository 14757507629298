<div class="social-links">
  <ul class="list-group list-group-horizontal">
    <li>
      <a href="https://www.linkedin.com/showcase/almentorforbiz/" target="_blank">
        <svg>
          <use href="assets/images/sprite.svg#linkedin"/>
        </svg>
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/almentorforBiz" target="_blank">
        <svg>
          <use href="assets/images/sprite.svg#facebook"/>
        </svg>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/almentorforbiz/?hl=en" target="_blank">
        <svg>
          <use href="assets/images/sprite.svg#instagram"/>
        </svg>
      </a>
    </li>
    <li>
      <a href="https://youtube.com/@almentorforbusiness7948" target="_blank">
        <svg>
          <use href="assets/images/sprite.svg#youtube"/>
        </svg>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/AlmentorforBiz" target="_blank">
        <svg>
          <use href="assets/images/sprite.svg#twitter"/>
        </svg>
      </a>
    </li>
  </ul>
</div>
