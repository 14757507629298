import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from 'package.json';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.infrastructure !== 'local') {
  Sentry.init({
    dsn: 'https://74412270d88f2b8346a03c2e4fe3ccad@o4505516055920640.ingest.us.sentry.io/4506863427649536',
    environment: environment.environmentName,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [environment.platforms.base.link],
        networkCaptureBodies: true
      }),
    ],
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.2,
    release: packageJson.sentryRelease,
  });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
