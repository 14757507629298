<div class="auth-content login-container">
  <div class="auth-header">
    <h5 class="auth-title text-center">{{'login_container.page_title' | translate}}</h5>
    <p class="text-center">{{'login_container.existing_account' | translate}}</p>
  </div>
  <div class="auth-body">
    <div class="row">
      <div class="col-12 col-md-6 d-flex justify-content-center mt-2 social-button-container">
        <alm-root-facebook-button
          [facebookId]="facebookId"
          (onFacebookAccessTokenReceived)="facebookLogin($event)"
          [title]="'login_container.login_method' | translate | translateCut: 0">
        </alm-root-facebook-button>
      </div>
      <div class="col-12 col-md-6  d-flex justify-content-center mt-2 social-button-container">
        <alm-root-google-button
          [googleId]="googleId"
          [redirectUrl]="redirectUrl"
          (onGoogleAccessTokenReceive)="googleLogin($event)"
          [title]="'login_container.login_method' | translate | translateCut: 1">
        </alm-root-google-button>
      </div>
    </div>

    <p class="or"><span>{{'login_container.or' | translate}}</span></p>

    <alm-root-login-form
      (onLogin)="emailLogin($event)"
      (onForgetPasswordBtnClicked)="forgetPasswordBtnClicked()" [loginIsClicked]="loginIsClicked">
    </alm-root-login-form>
  </div>
  <div class="auth-footer">
    <p class="text-center">{{'login_container.no_account' | translate | translateCut: 0}}
      <button class="font-weight-bold btn btn-link link-underline"
              (click)="signupButtonClicked()">{{'login_container.no_account' | translate | translateCut: 1}}
      </button>
    </p>
  </div>
</div>
