import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

interface ITransferableSessionItem {
  key: string;
  value: string;
}
const GET_SESSION_STORAGE = 'getSessionStorage';
const SET_SESSION_STORAGE = 'setSessionStorage';
const REMOVE_SESSION_STORAGE = 'removeSessionStorage';

@Injectable({
  providedIn: 'root'
})
export class SharedSessionStorageService {



  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformServer(this.platformId)) return;
    this.initSharedSessionStorageListener();
  }

  private initSharedSessionStorageListener() {
    const sessionStorage_transfer = (event: StorageEvent) => {
      if(!event.newValue) return;
      switch (event.key) {
        case GET_SESSION_STORAGE:
          // another tab asked for the sessionStorage item -> send it if exists
          const existsSessionStorageItem = sessionStorage.getItem(event.newValue);
          if (existsSessionStorageItem) {
            this.shareSessionItem({
              key: event.newValue,
              value: existsSessionStorageItem,
            });
          }
          return;
        case SET_SESSION_STORAGE:
          // another tab sent data <- get it
          const transferredItem: ITransferableSessionItem = JSON.parse(event.newValue);
          if (!transferredItem.key || !transferredItem.value) return;
          sessionStorage.setItem(transferredItem.key, transferredItem.value);
          return;
        case REMOVE_SESSION_STORAGE:
          sessionStorage.removeItem(event.newValue);
      }
    };

    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
      // @ts-ignore
      window.attachEvent("onstorage", sessionStorage_transfer);
    }
  }

  syncItem(key: string) {
    if (isPlatformServer(this.platformId)) return;
    localStorage.setItem(GET_SESSION_STORAGE, key);
    localStorage.removeItem(GET_SESSION_STORAGE);
  }

  setItem(key: string, value: string) {
    if (isPlatformServer(this.platformId)) return;
    sessionStorage.setItem(key, value);
    this.shareSessionItem({key, value});
  }

  getItem(key: string): string | null {
    if (isPlatformServer(this.platformId)) return null;
    return sessionStorage.getItem(key);
  }

  removeItem(key: string) {
    if (isPlatformServer(this.platformId)) return;
    sessionStorage.removeItem(key);
    localStorage.setItem(REMOVE_SESSION_STORAGE, key);
    localStorage.removeItem(REMOVE_SESSION_STORAGE);
  }


  private shareSessionItem(itemToTransfer: ITransferableSessionItem) {
    if (isPlatformServer(this.platformId)) return;
    localStorage.setItem(SET_SESSION_STORAGE, JSON.stringify(itemToTransfer));
    localStorage.removeItem(SET_SESSION_STORAGE);
  }
}
