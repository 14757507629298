import {Injectable} from '@angular/core';
import {SocialAuthService} from "@services/social-auth/social-auth.service";
import {DynamicLoadService} from "@services/dynamic-load/dynamic-load.service";
import {ActivatedRoute} from "@angular/router";

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService extends SocialAuthService {

  private googleClient: any;

  constructor(private dynamicLoadService: DynamicLoadService, private rout: ActivatedRoute) {
    super();
  }

  loadGoogleScript(googleId: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.dynamicLoadService.externalScript('google-jsgsi', 'https://accounts.google.com/gsi/client')
        .then((loaded: boolean) => {
          this.initGoogle(googleId).then((googleClient) => {
            this.googleClient = googleClient;
            resolve(true);
          });
        });
    });
  }

  private initGoogle(googleId: string): Promise<any> {
    return new Promise<any>(resolve => {
      let client = google.accounts.oauth2.initCodeClient({
        client_id: googleId,
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'select_account',
        ux_mode: 'redirect',
      });
      resolve(client);
    });
  }

  private requestGoogleCode(redirectUrl: string): Promise<any> {
    return new Promise<string | void>((resolve, reject) => {
      if (this.googleClient) {
        // set timeout to give time for tracking events
        setTimeout(() => {
          this.googleClient.redirect_url = redirectUrl;
          this.googleClient.requestCode();
          resolve();
        }, 300)
      } else {
        reject('GOOGLE ERROR');
      }
    });
  }

  getUserCodeFromGoogle(redirectURL: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      localStorage.setItem('sp', 'GOOGLE');
      this.requestGoogleCode(redirectURL)
        .then(() => {
          resolve();
        }).catch(reject);
    });
  }


  shouldCheckForGoogleLogin(): boolean {
    let userTry = this.hasUserTriedSocialLoginInNotSupportedBrowser();
    if (userTry && userTry == 'GOOGLE') {
      localStorage.removeItem('sp');
      return true;
    } else {
      return false;
    }
  }
}
