<div class="social-signup-container">
  <div class="row">
    <div class="col-12 d-flex justify-content-center mt-2 social-button-container">
      <alm-root-facebook-button
        [facebookId]="facebookId"
        (onFacebookAccessTokenReceived)="facebookLogin($event)"
        [title]="'sign_up_container.sign_up_method' | translate | translateCut: 0">
      </alm-root-facebook-button>
    </div>
    <div class="col-12  d-flex justify-content-center mt-2 social-button-container">
      <alm-root-google-button
        [googleId]="googleId"
        [redirectUrl]="redirectUrl"
        (onGoogleAccessTokenReceive)="googleLogin($event)"
        [title]="'sign_up_container.sign_up_method' | translate | translateCut: 1">
      </alm-root-google-button>
    </div>
  </div>
  <p class="or"><span>{{'sign_up_container.or' | translate}}</span></p>
  <p class="text-center mb-0">
    <button (click)="emailSignupButtonClicked()" class="btn btn-social btn-alm-filled w-100 mb-0">
      <svg width="22" height="18">
        <use href="assets/images/sprite.svg#email" />
      </svg>
      {{'sign_up_container.sign_up_method' | translate | translateCut: 2}}
    </button>
  </p>
  <p class="text-center terms">
    {{'sign_up_container.terms_conditions' | translate | translateCut: 0}}
    <a href="https://about.almentor.net/terms-conditions" target="_blank" class="btn btn-link link-underline">
      {{'sign_up_container.terms_conditions' | translate | translateCut: 1}}
    </a>
  </p>
</div>
