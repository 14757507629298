import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceManagementDialogComponent} from './device-management-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {DeviceManagementModule} from "@components/device-management/device-management.module";
import {TranslateModule} from "@ngx-translate/core";
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';


@NgModule({
  declarations: [DeviceManagementDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    DeviceManagementModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class DeviceManagementDialogModule {
}
