export const numberListGenerator = (min: number, max: number): number[] => {
  let generatedNumberList: number[] = [];
  let listLength = (max - min) + 1;
  for (let i = 0; i < listLength; i++) {
    generatedNumberList.push((min + i));
  }
  return generatedNumberList;
}

export const stringListGenerator = (min: number, max: number, round: boolean): string[] => {
  let generatedStringList: string[] = [];
  let listLength = (max - min) + 1;
  for (let i = 0; i < listLength; i++) {
    let item = round ? ('0' + parseInt((min + i).toString(), 10)).slice(-2) : (min + i).toString();
    generatedStringList.push(item);
  }
  return generatedStringList;
}

export const filterArrayByName = (searchString: string, source: any[]): any[] => {
  if (!searchString) {
    return source;
  }
  return source.filter((item) => item.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
}
