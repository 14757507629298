import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifyEmailComponent } from './verify-email.component';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';



@NgModule({
    declarations: [VerifyEmailComponent],
    exports: [
        VerifyEmailComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule
  ]
})
export class VerifyEmailModule { }
