import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IHttpError} from "@interfaces/common/http.interface";
import {UiLoaderService} from "@services/ui-loader/ui-loader.service";
import {SnackBarControlService} from "@services/snack-bar/snack-bar-control.service";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {LanguageControlService} from "@services/language/language-control.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {isPlatformBrowser} from "@angular/common";
import {AuthControlV2Service} from "@login/shared/services/auth-control/auth-control-v2.service";
import {skip, skipWhile} from 'rxjs/operators';
import {environment} from "@environments/environment";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {ZendeskService} from "@services/zendesk/zendesk.service";
import {SharedSessionStorageService} from '@services/shared-session-storage/shared-session-storage.service';
import {BrazeUserEventsService} from "@services/marketing/braze/events/braze-user-events.service";
import {ELoginTypes} from "@interfaces/authorized-user/user.interface";
import {TrackingService} from "@services/tracking/tracking.service";
import {lastValueFrom} from "rxjs";
import {REDIRECT} from "@constants/session-storage.constants";
import commonAr from "@shared-translations/http-errors/common-ar.json";
import commonEn from "@shared-translations/http-errors/common-en.json";
import authAr from "@shared-translations/http-errors/auth-ar.json";
import authEn from "@shared-translations/http-errors/auth-en.json";

@Component({
  selector: 'alm-login-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  showActivatedMessage: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private uiLoaderService: UiLoaderService,
              private snackbarControl: SnackBarControlService,
              private authControl: AuthControlV2Service,
              private authV2Service: AuthV2Service,
              private languageControl: LanguageControlService,
              private appRedirects: AppRedirectsService,
              private connectorService: ConnectorV2Service,
              @Inject(PLATFORM_ID) private platformId: any,
              private gtmService: GoogleTagManagerService, private zendeskService: ZendeskService,
              private sharedSessionStorageService: SharedSessionStorageService,
              private brazeUserEventsService: BrazeUserEventsService,
              private trackingService: TrackingService,
              ) {
    this.languageControl.setTranslations('ar', {...commonAr,...authAr});
    this.languageControl.setTranslations('en', {...commonEn,...authEn});
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (Object.keys(queryParams).length > 0) {
        this.getActivationParamsFromURL(queryParams);
        let oldAppRedirect = this.appRedirects.getRedirectParsedParamsFromSessionStorage();
        if (oldAppRedirect && !queryParams.hasOwnProperty('pf') && !queryParams.hasOwnProperty('sd')) {
          this.authControl.platformCode = oldAppRedirect.platform ?? null;
          this.authControl.subDomain = oldAppRedirect.subDomain ?? null;
        } else {
          let appRedirect = this.appRedirects.parseRedirectParams(queryParams);
          if (appRedirect) {
            this.authControl.platformCode = appRedirect.platform;
            this.authControl.subDomain = appRedirect.subDomain;
            this.appRedirects.saveRedirectParamsToSessionStorage(appRedirect);
          }
        }
        let nullParams: { [key: string]: null } = {};
        for (let param in queryParams) {
          if (queryParams.hasOwnProperty(param)) {
            nullParams[param] = null;
          }
        }
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: nullParams,
          queryParamsHandling: 'merge',
          state: queryParams,
          replaceUrl: true
        });
      } else {
        let oldAppRedirect = this.appRedirects.getRedirectParsedParamsFromSessionStorage();
        if (oldAppRedirect && !queryParams.hasOwnProperty('pf') && !queryParams.hasOwnProperty('sd')) {
          this.authControl.platformCode = oldAppRedirect.platform ?? null;
          this.authControl.subDomain = oldAppRedirect.subDomain ?? null;
        }
      }
    });

    this.scriptsLoader();
  }

  ngOnInit(): void {
    this.sharedSessionStorageService.syncItem(REDIRECT);
    this.connectorService.connectorLoaded.pipe(skip(1)).toPromise().then((loaded) => {
      this.connectorService.connectorUser.pipe(
        skip(1),
        skipWhile(u =>  !this.connectorService.isCheckingAuthorization)
      ).subscribe((user) => {
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            if (user) {
              this.router.navigate(['/redirect-center'], {queryParams: {'prevError': '001'}});
            } else {
              this.router.navigate(['/login']);
            }
          }, 1500);
        }
      });
    });
  }

  private getActivationParamsFromURL(queryParams: any) {
    if (queryParams['v'] && queryParams['g']) {
      let v = parseInt(queryParams['v'].replace(/,/g, ''));
      let g = queryParams['g'];
      this.activeUser(v, g);
    }
  }

  private scriptsLoader() {
    this.gtmService.loadGtm(environment.gtmID);
    this.zendeskService.loadZendeskWidget('ca70126b-03f9-45fb-8bb3-daead16e4adf');
  }

  private activeUser(v: number, g: string) {
    this.uiLoaderService.startUiLoader('login-loader');
    this.authV2Service.activateUser('login', v, g)
      .then(activateData => {
        lastValueFrom(this.languageControl.translate('user.activated')).then((message) => {
          this.snackbarControl.openInfoSnackbar(message);
        });
        this.uiLoaderService.stopUiLoader('login-loader');
        if (activateData.platformCodes.includes(environment.platforms.b2c.code)) {
          this.brazeUserEventsService.sendBrazeSignUpEvent(activateData, ELoginTypes.EMAIL);
          this.trackingService.sendEvent('user_account_activated', {
            event_properties: undefined,
            user_properties: {
              set: {
                user_id: activateData.email,
                activation_date: activateData.activationDate,
              }
            }
          });
        }
      }).catch((e: IHttpError) => {
      this.uiLoaderService.stopUiLoader('login-loader');
      this.snackbarControl.openHttpErrorSnackbar(e.code);
    });
  }

}
