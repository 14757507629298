import {Component, DestroyRef, EventEmitter, inject, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {LanguageControlService} from '@services/language/language-control.service';
import {MatInput} from "@angular/material/input";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

interface OtbFormGroup {
  otp0: AbstractControl<string>,
  otp1: AbstractControl<string>,
  otp2: AbstractControl<string>,
  otp3: AbstractControl<string>,
}

@Component({
  selector: 'alm-root-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {

  @Output() formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() verificationCode: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  private digitsPattern: string = '^[0-9]*$';
  otpForm: FormGroup<OtbFormGroup>;
  @ViewChildren(MatInput) inputs: QueryList<MatInput>;
  destroyRef = inject(DestroyRef);

  constructor(private fb: UntypedFormBuilder, private languageControl: LanguageControlService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.otpForm = this.fb.group({
      otp0: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      otp1: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      otp2: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
      otp3: new UntypedFormControl('', Validators.compose([Validators.pattern(this.digitsPattern), Validators.required,
        Validators.maxLength(1), Validators.minLength(1)])),
    });
  }

  ngOnInit(): void {
    this.otpForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status) => {
      if (status == 'VALID') {
        this.formStatus.emit(false);
        this.sendVerificationCode();
      } else {
        this.formStatus.emit(true);
      }
    });

    /*this.otpForm.valueChanges.subscribe(value => {
      // Automatically move focus when the value is updated
      Object.keys(value).forEach((key, index) => {
        if (value[key].length === 1 && index < 3) {
          this.inputs.toArray()[index + 1].focus();
        }
      });
    });*/
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // If the input contains non-numeric characters, clear it
    if (/\D/.test(value)) {
      input.value = '';
    } else {
      // Move to the next input field if numeric value is entered
      if (value.length === 1 && index < this.inputs.length - 1) {
        this.inputs.toArray()[index + 1].focus();
      }
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;

    if (event.key === 'Backspace' && !input.value && index > 0) {
      this.inputs.toArray()[index - 1].focus();
    }
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData('text');

    if (!pasteData || pasteData.length !== 4 || isNaN(Number(pasteData))) {
      return;
    }

    this.otpForm.setValue({
      otp0: pasteData[0],
      otp1: pasteData[1],
      otp2: pasteData[2],
      otp3: pasteData[3]
    });
  }

  getFormFieldsError(fieldControls: AbstractControl[]): number {
    for (let fieldControl of fieldControls) {
      if (fieldControl?.touched) {
        if (fieldControl?.errors?.required) {
          return 1;
        }
        if (fieldControl?.errors?.pattern?.requiredPattern == this.digitsPattern || fieldControl?.errors?.minlength
          || fieldControl?.errors?.maxLength) {
          return 2;
        }
      }
    }
    return 0;
  }

  sendVerificationCode(): void {
    let activationCode = this.otpForm.value.otp0 +
      this.otpForm.value.otp1 +
      this.otpForm.value.otp2 +
      this.otpForm.value.otp3;
    this.verificationCode.emit(activationCode);
  }

  submitCode() {
    if (this.otpForm.valid) {
      let activationCode = this.otpForm.value.otp0 +
        this.otpForm.value.otp1 +
        this.otpForm.value.otp2 +
        this.otpForm.value.otp3;
      this.onSubmit.emit(activationCode);
    }
  }
}
