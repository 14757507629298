import {inject, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {environment} from '@environments/environment';
import {ELanguage, ETheme, IConnectorUserInfo, IMessageUser} from '@interfaces/common/connector.interface';
import {BehaviorSubject} from 'rxjs';
import {CookieService} from 'ngx-cookie';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class FirebaseFirestoreService {

  userInfo: BehaviorSubject<IConnectorUserInfo> = new BehaviorSubject<IConnectorUserInfo>({
    theme: ETheme.Dark,
    lang: ELanguage.AR,
    user: null
  });
  private deviceId?: string;
  firestoreService: AngularFirestore;
  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private cookieService: CookieService,
              ) {
    if (isPlatformBrowser(platformId)) {
      this.firestoreService = inject(AngularFirestore);
    }
  }

  initFireStore(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      let deviceId = this.getDeviceCookie();
      if (deviceId) {
        this.checkIfDeviceExist(deviceId).then((isExist) => {
          if (isExist) {
            this.deviceId = deviceId;
            this.readUserValue(<string>deviceId);
            resolve(true);
          } else {
            this.createUserValue().then((deviceId) => {
              this.deviceId = deviceId;
              this.readUserValue(deviceId);
              resolve(true);
            });
          }
        });
      } else {
        this.createUserValue().then((deviceId) => {
          this.deviceId = deviceId;
          this.readUserValue(deviceId);
          resolve(true);
        });
      }
    });
  }

  updateLanguage(lang: ELanguage) {
    if (this.userInfo.getValue().lang !== lang) {
      this.updateUserValue({lang: lang});
    }
  }

  updateTheme(theme: ETheme) {
    if (this.userInfo.getValue().theme !== theme) {
      this.updateUserValue({theme: theme});
    }
  }

  updateUser(user: IMessageUser | null): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.updateUserValue({user: user}).then(resolve).catch(() => {resolve(false)});
    });
  }

  private setDeviceCookie(deviceId: string) {
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.cookieService.put('alm-ldi', deviceId, {
      domain: environment.platforms.base.link,
      path: '/',
      expires: cookieDate,
      sameSite: false,
      secure: true,
    });
  }

  private deleteWrongCookies() {
    this.cookieService.remove('alm-ldi', {
      domain: environment.platforms.base.link,
      path: '/courses',
      sameSite: "none",
      secure: true,
    });
  }

  private getDeviceCookie(): string | undefined {
    this.deleteWrongCookies();
    return this.cookieService.get('alm-ldi');
  }

  private checkIfDeviceExist(deviceId: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.firestoreService.doc(`users/${deviceId}`).get().subscribe({
        next: (data) => {
          resolve(data.exists)
        },
        error: (e) => {
          resolve(false);
        }
      });
    });
  }

  private readUserValue(deviceId: string) {
    this.firestoreService.doc<IConnectorUserInfo>(`users/${deviceId}`).valueChanges()
      .subscribe((userInfo) => {
        if (userInfo) {
          this.userInfo.next(userInfo);
        }
      });
  }

  private createUserValue(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let deviceId = this.firestoreService.createId();
      this.firestoreService.collection('users').doc(deviceId).set(this.userInfo.getValue()).then(() => {
        this.setDeviceCookie(deviceId);
        resolve(deviceId);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  private updateUserValue(updatedUserInfo: { lang?: ELanguage, theme?: ETheme, user?: IMessageUser | null }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.deviceId) {
        this.firestoreService.doc(`users/${this.deviceId}`).update(updatedUserInfo).then(() => {
          resolve(true);
        }).catch((e) => {
          resolve(false);
        })
      } else {
        resolve(false);
      }
    });
  }
}
