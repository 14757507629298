import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';

export class AlmMissingTranslationHandler implements MissingTranslationHandler {

  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams?.hasOwnProperty('default')) {
      // @ts-ignore
      return params.interpolateParams['default'];
    }
    return params.key;
  }
}
