import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {take} from "rxjs/operators";
import {Observable, ReplaySubject} from "rxjs";

type BrazeSDK = typeof import('@braze/web-sdk/index')

export interface IBrazePurchase {
  productId: string;
  price: number;
  currencyCode?: string;
  quantity?: number;
  purchaseProperties?: object;
}

@Injectable({
  providedIn: 'root'
})
export class BrazeService {
  braze?: BrazeSDK;
  braze$: ReplaySubject<BrazeSDK> = new ReplaySubject<BrazeSDK>();

  constructor() {
  }

  initBraze(): void {
    if (this.braze) {
      this.braze$.next(this.braze);
    } else {
      import('@braze/web-sdk').then((braze) => {
        this.braze = braze;
        this.loadBraze();
        this.braze$.next(this.braze);
      });
    }
  }

  initWithDelay(seconds: number) {
    setTimeout(() => {
      this.initBraze();
    }, seconds * 1000);
  }

  getBrazeInstance(): Observable<BrazeSDK> {
    return this.braze$.pipe(take(1));
  }

  loadBraze(): void {
    if (this.braze) {
      this.braze.initialize(environment.braze.apiKey, {
        baseUrl: environment.braze.baseUrl,
        allowUserSuppliedJavascript: true
      });
      this.braze.subscribeToInAppMessage((inAppMessage) => {
        this.braze?.showInAppMessage(inAppMessage);
      });
      this.braze.openSession();
    }
  }

  setUserId(userId: string): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.changeUser(userId);
    });
  }

  logEvent(eventName: string, eventProperties?: object): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.logCustomEvent(eventName, eventProperties);
    });
  }

  setCustomUserAttribute(
    key: string,
    value: number | boolean | Date | string | string[] | null | object
  ): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.getUser()?.setCustomUserAttribute(key, <any>value);
    });
  }

  addToCustomAttributeArray(
    key: string,
    value: string
  ): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.getUser()?.addToCustomAttributeArray(key, <any>value);
    });
  }

  setEmail(email: string): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.getUser()?.setEmail(email);
    });

  }

  setName(name: string): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.getUser()?.setFirstName(name);
    });
  }

  incrementCustomUserAttribute(
    key: string,
    value: number
  ): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.getUser()?.incrementCustomUserAttribute(key, value);
    });
  }

  logPurchase({productId, price, currencyCode, quantity, purchaseProperties}: IBrazePurchase): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.logPurchase(productId, price, currencyCode, quantity, purchaseProperties);
    });
  }

  immediateDataFlush(): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.requestImmediateDataFlush();
    });
  }

  requestPushPermission(): void {
    this.getBrazeInstance().subscribe(braze => {
      braze.requestPushPermission();
    });
  }
}
