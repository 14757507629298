import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'alm-root-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {

  @Input() userSubscribed?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
