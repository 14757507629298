import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from "rxjs/operators";
import {SentryService} from "@services/sentry/sentry.service";
import {
  LEGACY_DIRECT_URL_HEADER,
  REQUESTER_APP_HEADER_NAME,
  REQUESTER_APP_HEADER_VALUE
} from "@constants/http.constants";
import {PlatformType} from "@interfaces/common/http.interface";

@Injectable()
export class ApiGatewayInterceptor implements HttpInterceptor {
  constructor(private httpClient: HttpClient,
              private sentryService: SentryService,
              @Inject(REQUESTER_APP_HEADER_VALUE) private requesterAppHeader: PlatformType,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let updatedRequest = request.clone({
      setHeaders: {
        [REQUESTER_APP_HEADER_NAME]: this.requesterAppHeader
      },
    });

    const legacyDirectUrl = updatedRequest.headers.get(LEGACY_DIRECT_URL_HEADER);
    if (!legacyDirectUrl) return next.handle(updatedRequest);

    updatedRequest = updatedRequest.clone({
      headers: updatedRequest.headers.delete(LEGACY_DIRECT_URL_HEADER),
    });
    return next.handle(updatedRequest).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.reportErrorToSentry(errorResponse);
        let legacyUrlRequest = updatedRequest.clone({
          url: legacyDirectUrl
        });
        return next.handle(legacyUrlRequest);
      }),
    );


  }

  private reportErrorToSentry(errorResponse: HttpErrorResponse) {
    // Distinguish between WAF/network blocking and an HTTP error response.
    const sentryErrorMessage: string = errorResponse.status === 0
      ? 'Http Request Blocked'
      : 'API Gateway Http Error';
    this.sentryService.reportHttpErrorResponse(sentryErrorMessage, errorResponse);
  }
}

