import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Title} from "@angular/platform-browser";
import {DEFAULT_PAGE_TITLE} from "@constants/ui.constants";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  title$: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_PAGE_TITLE);

  constructor(
    private title: Title,
  ) {
    this.title$.subscribe(title => {
      this.title.setTitle(title);
    })
  }

  setTitle(title: string) {
    this.title$.next(title);
  }

  getTitle(): string {
    return this.title$.getValue();
  }
}
