import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthContainerComponent } from './auth-container.component';
import {LoginContainerModule} from "@components/login-container/login-container.module";



@NgModule({
  declarations: [AuthContainerComponent],
  exports: [
    AuthContainerComponent
  ],
  imports: [
    CommonModule,
    LoginContainerModule
  ]
})
export class AuthContainerModule { }
